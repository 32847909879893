.separator {
  width: 100%;
}

.thin {
  height: 1px;
}

.normal {
  height: 2px;
}

.grey {
  background: rgba(29, 66, 109, 0.1);
}

:global(.darkMode) .grey {
  background: var(--borderDark)
}

.white {
  background: #fff;
}

.greyGradient {
  background: linear-gradient(270deg, rgba(29, 66, 109, 0) 0%, rgba(29, 66, 109, 0.15) 49.48%, rgba(29, 66, 109, 0) 100%);
  opacity: 0.5;
}
