.categoryList {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px -10px;
  position: relative;
  z-index: 2;
}

.categoryItem {
  padding-left: 15px;
}

.categoryItem:last-child {
  padding-right: 15px;
}

.categoryButton {
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: 2px solid var(--primatyColorLight);
  color: var(--primatyColorLight);
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  padding: 0 12px;
  cursor: pointer;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  font-family: var(--primaryFont);
  will-change: transform;
  transition: 0.2s ease;
  min-width: 135px;
  justify-content: center;
  text-align: center;
}

:global(.darkMode) .categoryButton {
  border-color: var(--borderDark);
  color: var(--textSubDark);
}

.categoryButton:active {
  transform: scale(1.02);
}

.categoryButton:not(.categoryButtonActive):hover {
  border-color: var(--primatyColor);
  color: var(--primatyColor);
}

:global(.darkMode) .categoryButton:not(.categoryButtonActive):hover {
  border-color: var(--borderDark);
  color: var(--textMainDark);
  transform: scale(1.02);
}

.categoryButtonActive {
  cursor: default;
  background-color: var(--primatyColor);
  border-color: var(--primatyColor);
  color: white;
  pointer-events: none;
}
:global(.darkMode) .categoryButtonActive {
  color: var(--textMainDark);
  border-color: var(--primatyColor);
}

.cardList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);
  gap: 20px;
  padding: 40px 0;
}

@media screen and (--maxWidth900) {
  .cardList > div {
    width: 100%;
  }

  .categoryList::-webkit-scrollbar {
    display: none;
  }

  .categoryList {
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-top: 35px;
    margin-bottom: 0;
  }

  .cardList {
    grid-template-columns: none;
    padding: 0;
  }

  .cardList > div {
    width: auto;
  }
}
