.container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.coverImage {
  position: relative;
  width: 1200px;
  height: 127px;
  background: linear-gradient(270deg, rgba(32, 145, 239, 0.1) 0%, rgba(26, 157, 225, 0.1) 31.11%, rgba(13, 188, 205, 0.1) 73.89%, rgba(1, 214, 185, 0.1) 100%);
}

@media (--maxWidth2000) {
  .coverImage {
    width: 100%;
  }
}

.coverImageExisting {
  width: 100%;
  max-height: 127px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.coverImageExisting img {
  object-fit: cover;
  width: 1200px;
}

@media (--maxWidth900) {
  .coverImageExisting img {
    height: 127px;
  }
}

@media (--maxWidth2000) {
  .coverImageExisting img {
    width: 100%;
  }
}

.editButtonCommon {
  padding: 7px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  background-blend-mode: normal, soft-light, normal;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
  border-radius: 20px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
}

.editButtonCommon img {
  object-fit: contain;
}

.editCoverImageButton {
  composes: editButtonCommon;
  bottom: 16px;
  right: 20%;
}

.profileImageBlock {
  position: absolute;
  bottom: -40px;
}

@media (--maxWidth1200) {
  .profileImageBlock {
    z-index: 1;
  }
}

.profileDefaultImage {
  width: 105px;
  height: 105px;
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editProfileImageButton {
  composes: editButtonCommon;
  position: absolute;
  right: 0;
  bottom: 0;
}

.pictureWrapper {
  border-radius: 50%;
  position: relative;
  border: 3px solid #FFFFFF;
}

.pictureWrapper img {
  width: 105px;
  height: 105px;
  border-radius: 50%;
}

.smallSize img {
  width: 44px;
  height: 44px;
}

.extraSmallSize img{
  width: 28px;
  height: 28px;
}

.imageShadow {
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13)
}
