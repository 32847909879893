.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 610px;
  margin: 0 auto;
  text-align: center;
}

.borderIcon {
  margin-bottom: 12px;
  background: var(--colorGradientLight);
  height: 107px;
  width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
}

.borderIcon:before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);;
  margin: 0;
  background: #FFFFFF;
  z-index: 1;
  border-radius: 50%;
}

.roundCardIcon {
  position: relative;
  z-index: 2;
  height: 56px;
  width: 56px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.1);
}

.infoText {
  margin-bottom: 10px;
}
