.container .knobs:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 9px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 3px 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.35), 0 10px 10px -5px rgba(0, 0, 0, 0.20);
}

:global(.darkMode) .container .knobs:before {
  background-color: var(--bgDark);
}

.container .checkbox:checked + .knobs:before {
  content: '';
  left: 24px;
  background-color: #fff;
}

:global(.darkMode) .container .checkbox:checked + .knobs:before {
  background-color: var(--bgDark);
}

.container .checkbox:checked ~ .layer {
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
}

.container .knobs, .container .knobs:before, .container .layer {
  transition: 0.3s ease all;
}

.knobs, .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container {
  position: relative;
  top: 50%;
  width: 46px;
  min-width: 46px;
  height: 24px;
  overflow: hidden;
  margin: 0;
}

.container, .container .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #d3d3d3;
  transition: 0.3s ease all;
  z-index: 1;
}
