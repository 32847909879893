.spacer {
  display: block;
  width: 100%;
}

.extraSmall {
  padding-bottom: var(--offsetExtraSmall);
}

.small {
  padding-bottom: var(--offsetSmall);
}

.medium {
  padding-bottom: var(--offsetMedium);
}

.large {
  width: 100%;
  padding-bottom: var(--offsetLarge);
}

.extraLarge {
  padding-bottom: var(--offsetExtraLarge);
}
