.sidebarItem {
  padding: var(--offsetMedium) 0;
  border-top: 1px solid #DAE7F0;
}

.sidebarItem:last-child {
  border-bottom: 1px solid #DAE7F0;
}

.borderOff:last-of-type {
  border-bottom: none;
}

.borderOff:first-of-type{
  border-top: none;
}
