.popup {
    margin: 25px 0 32px;
    width: 680px;
}

@media (--maxWidth900) {
    .popup {
        width: 90vw;
    }
}

.heading {
    margin: 0 35px 20px;
}

.formContent {
    margin: 0 35px;
}

.description {
    margin-top: var(--offsetLarge);
}

.textInput {
    margin-top: var(--offsetMedium);
}

.fileInput {
    margin-top: var(--offsetMedium);
}

.textArea {
    margin-top: 58px;
}

.textAreaDescription {
    margin: var(--offsetMedium) 0 var(--offsetLarge);
}

.cancelPseudoButton {
    cursor: pointer;
}

.buttonsBlock {
    margin: var(--offsetLarge) var(--offsetLarge) 0;
    display: flex;
    align-items: center;
    justify-content: end;
}

.cancelPseudoButton {
    margin-right: var(--offsetMedium);
}

.errorField {
    margin-bottom: 15px;
    font-family: var(--primaryFont);
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 121.5%;
    color: var(--redColor);
}

.agreement {
    margin: var(--offsetMedium) 0;
    font-size: 14px;
}

.agreement a {
    background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
