.widget {
    margin-top: var(--offsetExtraLarge);
}

.pdfsBlock {
    margin-top: var(--offsetMedium);
}

.pdfsBlock > * {
    margin-bottom: var(--offsetMedium);
}

.videosBlock {
    margin-bottom: var(--offsetMedium);
}

.videosBlockItem {
    margin-bottom: var(--offsetMedium);
}

.widgetTitle {
    margin-bottom: var(--offsetMedium);
}
