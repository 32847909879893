.mainControls {
  padding: 23px 10px 0;
  width: 100%;
  background: var(--primatyColor);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
  transition: 0.3s ease;
}

.mainWrapper {
  background: var(--primatyColor);
  display: flex;
  flex-direction: column;
  position: relative;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-grow: 1;
}

.videoPlayer video{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.controls {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  margin-bottom: 5px;
}

.fullScreen {
  margin-left: auto;
}

.track {
  cursor: pointer;
  width: 100%;
  display: block;
  height: 5px;
  background: rgba(255, 255, 255, .3);
  position: relative;
  margin-bottom: 7px;
  overflow: hidden;
}

.loader {
  background: #E4E4E7;
  width: 60%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}

.currentTime {
  width: 30%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: #218FEE;
}

.track input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.time {
  color: #fff;
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  text-align: center;
}

.previousButton {
  transform: rotate(180deg);
  position: relative;
  top: -3px;
}

.controlButtons {
  cursor: pointer;
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
}

.controlButtons:disabled {
  opacity: .5;
}

.muteButton {
  margin-right: 20px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.navigationButtons {
  position: absolute;
  top: -55px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  right: 26px;
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out .3s;
  color: #FFFFFF;
  z-index: 2;
}

.navigationButton {
  display: flex;
  align-items: center;
  justify-self: center;
  border: 2px solid currentColor;
  border-radius: 35px;
  font-size: 16px;
  font-weight: bold;
  padding: 9px 23px;
  background: var(--primatyColor);
  color: currentColor;
  cursor: pointer;
}

.navigationButton:disabled {
  opacity: .5;
}

.navigationButtonNext svg {
  margin-left: 10px;
}

.navigationButtonPrev svg{
  margin-right: 10px;
}

.mainWrapper:hover .navigationButtons {
  opacity: 1;
  visibility: visible;
}

.playerWrapper {
  position: relative;
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (--maxWidth1100) {
  .navigationButtons {
    position: relative;
    width: 100%;
    color: var(--primatyColor);
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    left: 0;
    margin: 35px auto 30px auto;
    top: 0;
    gap: 0;
  }

  .navigationButtons svg,
  .navigationButtons path{
    fill: var(--primatyColor)!important;
  }

  .navigationButton {
    margin: 0 5px;
    background: transparent;
  }
}

.iconButtonCenter {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.iconButtonCenterWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: #032245;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(3, 34, 69, 0.8);
  z-index: 1;
}

.iconButtonCenterWrapper svg{
  width: 55px;
  height: 55px;
  cursor: pointer;
  transform: scale(1.3);
}

.relativePosition {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.mainWrapperFullSize {
  cursor: none;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.mainWrapperFullSizeActiveCursor {
  cursor: auto;
}

.mainWrapperFullSize .mainControls{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 0.2s;
}

.mainWrapperFullSize .mainControls:hover {
  opacity: 1;
  cursor: auto;
}

.mainWrapperFullSizeActiveCursor .mainControls {
  opacity: 1;
}
