.select {
  width: 100%;
  position: relative;
}

.selectIcon {
  transition: .3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

.selectIconFocused {
  transform: rotate(-180deg);
}

.selectIcon svg {
  fill: var(--primaryColorDark);
}

.selectIconFocused svg {
  fill: var(--primatyColor);
}

.errorLabel > div > div {
  border: 1px solid var(--redColor);
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.requiredIcon {
  position: absolute;
  color: red;
  font-size: 14px;
  top: 23px;
  left: 16px;
}

@media (--maxWidth900) {
  .requiredIcon {
    top: 13px;
    left: 11px;
  }
}
