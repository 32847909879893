.pageContent {
    display: flex;
    justify-content: space-between;
    margin-top: var(--offsetLarge);
}

.pageContentBack {
    display: flex;
    justify-content: space-between;
}

@media (--maxWidth1100) {
    .pageContent {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.mainContentPart {
    width: 580px;
}

@media (--maxWidth600) {
    .mainContentPart {
        width: 100%;
    }
}

.applyWrapper {
    margin-top: var(--offsetExtraLarge);
    display: flex;
    flex-direction: column;
}

.applyWrapperInner {
    padding: var(--offsetMedium) 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.singleApply {
    margin-top: var(--offsetExtraLarge);
    display: flex;
    align-items: center;
    justify-content: center;
}

.backLinkWrapper {
    width: 580px;
}

.leftBackContent {
    width: 65px;
}

.rightBackContent {
    width: 280px;
}

.backLink {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
}

.socialIcon {
    position: sticky;
    top: 100px;
    display: grid;
    gap: 24px;
    text-align: center;
}

.mobileShare {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding:  17px 0;
    border-top: 1px solid #DDE3E9;
    border-bottom: 1px solid #DDE3E9;
    margin-top: var(--offsetLarge);
}

.mobileShare > div {
    margin: 15px;
}

@media (--maxWidth400) {
    .mobileShare > div {
        margin: 2%;
    }
}

@media (--maxWidth1100) {
    .backLink {
        display: none;
    }
}

.backLink svg {
    margin-right: 5px;
}

.mobileTargetPageSocial {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: -10px;
}

.otherPositionsWrapper {
    width: 780px;
    margin-left: 80px;
}


@media (--maxWidth1100) {
    .otherPositionsWrapper {
        margin: 0 auto;
        width: 100%;
    }
}

.mobileSeparator {
    margin-top: var(--offsetSmall);
}

.mobileClinicDescription {
    display: flex;
    flex-direction: column;
    margin-top: var(--offsetSmall);
}

.mobileClinicDescription > * {
    margin-bottom: 5px;
}

.mobileClinicDescription > *:last-child {
    margin-bottom: 0;
}

