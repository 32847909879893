.wrapper {
  list-style: none;
  margin: 0 6px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.row {
  display: flex;
  align-items: center;
}

.item {
  width: 6px;
  overflow: hidden;
  line-height: 0;
  direction: rtl;
}

.item.full {
  margin-left: 2px;
  direction: ltr;
}

.item svg {
  width: 12px;
  height: 12px;
}

.starLarge {
  width: 14px;
  cursor: pointer;
}

.starLarge svg {
  width: 30px;
  height: 30px;
}

.rating {
  white-space: nowrap;
}
