.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    max-height: 620px;
    height: 80vh;
    width: 80vw;
    padding: 30px 15px;
    text-align: center;
    margin: 0 auto;
    overflow: auto;
    grid-template-rows: 1fr auto;
}

.icon {
    margin-bottom: 10px;
    position: relative;
}

.icon:before {
    position: absolute;
    right: 110%;
    bottom: var(--offsetLarge);
    content: url('/icons/dicover/thankYouSecondaryIcon.svg');
}

.title {
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: var(--offsetLarge);
}

.description{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: var(--offsetLarge);
}

.buttonWrapper {
    min-width: 140px;
}
