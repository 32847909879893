.widget {
  display: flex;
  flex-direction: column;
}

.postedAtBlock {
  display: flex;
  margin-bottom: var(--offsetLarge);
}

@media (--maxWidth600) {
  .postedAtBlock {
    align-items: center;
  }
}

.postedAtBlock > *:first-child {
  margin-right: 4px;
}

.heading {
  margin-bottom: var(--offsetSmall);
  overflow-wrap: break-word;
}

.positionDataRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: var(--offsetMedium);
}

.positionDataRowLeftPart {
  display: flex;
  align-items: center;
}

.positionDataRowLeftPart > *:first-child {
  margin-right: var(--offsetLarge);
}

.workTypesBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--offsetSmall);
}

.workTypeBlock {
  background: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
  border-radius: 100px;
  padding: 4px 8px;
  white-space: nowrap;
}

.salaryWrapper {
  display: flex;
  align-items: flex-end;
}

.minSalary {
  margin-left: 4px;
}

@media (--maxWidth600) {
  .workTypeBlock {
    margin-left: 0;
  }
}

.locationIcon {
  min-width: 15px;
  min-height: 20px;
}

.positionDataRowRightPart {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  margin-left: var(--offsetSmall);
}

.mobileSeparator {
  margin-top: var(--offsetMedium);
}

.mobileClinicDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--offsetMedium);
}

.mobileClinicDescription > * {
  margin-bottom: 5px;
}

.mobileClinicDescription > *:last-child {
  margin-bottom: 0;
}

.mobileLocation {
  display: flex;
}

.mobileLocation > *:first-child {
  margin-right: var(--offsetExtraSmall);
  min-width: 10px;
  min-height: 13px;
}

.mobileCenteredText {
  text-align: center;
}
