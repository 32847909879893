.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.main {
  width: 1180px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 80px;
}

.headings {
  text-align: center;
  overflow-wrap: break-word;
  max-width: 90vw;
  margin: 48px auto 0;
}

.userType {
  margin: 24px auto 0;
  max-width: 90vw;
  text-align: center;
}

.content {
  display: flex;
  gap: 20px;
  margin: 48px auto;
}

.rightSidebar {
  display: grid;
  gap: var(--offsetExtraLarge);
}

@media (--maxWidth1200) {
  .content {
    flex-direction: column;
    margin: 48px auto;
  }
}

@media (--maxWidth900) {
  .content {
    flex-direction: column;
    margin: 48px 30px;
  }
}

.contentBlock {
  max-width: 880px;
}
