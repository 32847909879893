.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.main {
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  padding: 80px 15px;
  position: relative;
}

.fixedWidth {
  max-width: 1180px;
}

.fullWidth {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.offset80 {
  padding-top: 80px;
}

.offset50 {
  padding-top: 50px;
}

.offset15 {
  padding-top: 15px;
}

.offset0 {
  padding-top: 0;
}
