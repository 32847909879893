.main {
    padding: var(--offsetMedium);
    background: linear-gradient(66.36deg, rgba(1, 214, 185, 0.68) 13.19%, rgba(33, 143, 238, 0.68) 79.21%);
}

.checkItems {
    display: grid;
    width: fit-content;
    margin: 0 auto;
    grid-template-columns: repeat(4, max-content);
    row-gap: 10px;
    column-gap: 56px;
}

@media screen and (--maxWidth1200) {
    .checkItems {
        grid-template-columns: repeat(2, max-content);
    }
}

@media screen and (--maxWidth750) {
    .checkItems {
        grid-template-columns: repeat(1, max-content);
    }
}


.checkItem {
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.checkIcon {
    position: relative;
    margin-right: var(--offsetSmall);
}
