.main {
    max-width: 280px;
}

.header {
    max-width: 100%;
    position: relative;
}

.content {
    padding: var(--offsetMedium) var(--offsetMedium) var(--offsetLarge) var(--offsetMedium);
}

.title {
    text-align: center;
    margin-bottom: var(--offsetSmall);
}

.line {
    margin-bottom: var(--offsetSmall);
}

.descriptionText {
    text-align: center;
    margin-bottom: var(--offsetSmall);
}

.buttonTurnOn {
    display: flex;
    justify-content: center;
}

.buttonsList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.buttonOnActive {
    box-sizing: border-box;
    margin: 0 var(--offsetExtraSmall) var(--offsetExtraSmall) var(--offsetExtraSmall);
}

.jobDetailsWithEdit {
    margin-top: var(--offsetSmall);
}

.headerEditButton {
    position: absolute;
    top: -10px;
    left: -37px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerMobileWrapper {
    background: var(--colorGradienLighter);
}

.jobDetails,
.jobsList {
    margin-bottom: var(--offsetSmall);
}

.jobsList {
    text-align: center;
}

.jobDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primatyColorLight);
    font-size: 14px;
    line-height: 1.4;
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.jobDetails > div:not(:last-child) {
    margin-right: var(--offsetExtraSmall);
}

.jobDetails > div {
    white-space: nowrap;
}

.jobDetails > div:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.loadingWidget {
    padding: var(--offsetLarge);
}
