.profileImage {
  overflow: hidden;
  border-radius: 50%;
  margin-right: 12px;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 17px;
  align-items: center;
}

.name {
  min-width: 0;
  max-width: 100%;
  align-items: baseline;
}

.dark {
  color: var(--primaryColor);
}

.light {
  color: white;
}

.noMargin {
  margin-bottom: 0;
}

.nameWrapper {
  display: grid;
  max-width: 100%;
}

.location {
  display: flex;
}

.location > div:first-child {
  min-width: 10px;
}

.location > *:last-child {
  margin-left: 8px;
}

@media (--maxWidth600) {
  .mobileColumn.profileInfo {
    display: block;
    text-align: center;
  }

  .mobileColumn .rating {
    display: flex;
    justify-content: center;
  }



  .mobileColumn .profileImage {
    width: 95px;
    height: 95px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .location {
    justify-content: center;
  }
}
