.main {
  display: flex;
  align-content: center;
  border-radius: 9px;
}

.tab {
  padding: 6px 16px;
  border: 1px solid var(--primatyColorLight);
  cursor: pointer;
  color: var(--primatyColorLight);
  background: #FFFFFF;
}

.tab:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.tab:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.activeTab {
  background: var(--primatyColor);
  color: #FFFFFF;
}

@media (--maxWidth900) {
  .main {
    flex-direction: column;
  }

  .tab {
    padding: 6px;
  }

  .tab:first-child {
    border-radius: 9px 9px 0 0;
  }

  .tab:last-child {
    border-radius: 0 0 9px 9px;
  }
}
