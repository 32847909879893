.widget {
  width: 100%;
  padding: var(--offsetLarge);
  background: #FFFFFF;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.commonWidgetPart {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.leftWidgetPart {
  display: grid;
  gap: var(--offsetSmall);
}

.positionDataRow {
  display: flex;
  align-items: center;
}

.clinicNameBlock {
  display: flex;
  align-items: center;
}

.clinicNameBlock > *:first-child {
  margin-right: var(--offsetExtraSmall);
}

.clinicNameBlock > *:last-child {
  margin-right: var(--offsetLarge);
}

.clinicLocationBlock {
  display: flex;
  align-items: center;
}

.clinicLocationBlock > *:last-child {
  margin-left: 13px;
  margin-right: var(--offsetLarge);
}

.clinicLocationIcon {
  min-width: 15px;
}

.workTypesBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.workTypeBlock {
  background: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
  border-radius: 100px;
  padding: 4px 8px;
  white-space: nowrap;
  margin-right: var(--offsetSmall);
}

.rightWidgetPart {
  margin-left: var(--offsetSmall);
  display: flex;
  flex-direction: column;
  align-items: end;
}

.salaryBlock {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--offsetMedium);
  min-height: 44px;
}

.minSalary {
  margin-top: var(--offsetSmall);
  margin-left: 4px;
  white-space: nowrap;
}

.applyNowButton {
  margin-bottom: var(--offsetLarge);
  white-space: nowrap;
}

.isHiddenApply {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.entryDate {
  margin-right: var(--offsetLarge);
  display: flex;
  align-items: center;
}

.entryDate svg {
  width: 18px;
  height: 16px;
  min-width: 18px;
  margin-right: 10px;
}

.entryDate span {
  text-transform: capitalize;
}

.locationIcon svg {
  width: 15px !important;
  height: 20px !important;
}

.descriptionRow * {
  word-break: break-all;
}
