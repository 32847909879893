.iconsList {
    display: grid;
    gap: 24px;
}

.iconCard {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 3.74035px 26.1824px rgba(0, 82, 153, 0.13);
    width: 65px;
    height: 65px;
}


@media (--maxWidth1100) {
    .iconCard {
        width: 60px;
        height: 60px;
    }
}

.share button,
.share {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
