.card {
  display: flex;
  border-radius: 16px;
  max-width: 100%;
  width: 360px;
  overflow: hidden;
  position: relative;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, box-shadow;
  box-shadow: var(--cardShadow);
  margin: 0 auto;
}

.card:hover {
  transform: scale(1.04);
  box-shadow: var(--cardShadowLarge);
  z-index: 1;
}

.label {
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  background-color: var(--primatyColor);
  padding: 4px 10px;
  margin-bottom: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 25px;
}

.media {
  padding-bottom: 160%;
  width: 100%;
  height: 100%;
  user-select: none;
  background-image: linear-gradient(
    270deg,
    #2091ef 0%,
    #1a9de1 31.11%,
    #0dbccd 73.89%,
    #01d6b9 100%
  );
  position: relative;
}

.media::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 36.51%,
    rgba(0, 0, 0, 0.528) 100%
  );
}

.mediaElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.2s ease;
}

.title {
  display: flex;
  margin-bottom: 15px;
  transition: 0.2s ease;
  will-change: color, transform;
  overflow-wrap: anywhere;
}

.title:hover {
  opacity: 0.8;
}

@media (--maxWidth600) {
  .card {
    width: 270px;
  }
}
