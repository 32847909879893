.benefitItem {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
}

.image {
    margin-right: 10px;
    width: 30px;
}

.benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

@media screen and (--maxWidth600) {
    .benefits {
        display: grid;
        grid-template-columns: auto;
        width: fit-content;
        align-items: start;
    }

    .benefitItem {
        justify-content: flex-start;
    }
}
