.widget {
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 100px;
}

@media (--maxWidth1100) {
    .widget {
        width: 580px;
    }
}

@media (--maxWidth600) {
    .widget {
        width: calc(100vw - 30px);
    }
}

.card {
    width: 280px;
    padding-top: 24px;
    padding-bottom: 40px;
    background: #FFFFFF;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--offsetLarge);
}

.clinicNameBlock {
    margin-bottom: var(--offsetLarge);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clinicNameText {
    max-width: 250px;
    text-align: center;
    overflow-wrap: break-word;
}

.clinicWebsiteText {
    composes: clinicNameText;
    margin-top: 4px;
}

.clinicEstablishmentBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--offsetLarge);
}

.positionStatusBlock {
    width: 100%;
    padding: var(--offsetSmall);
    text-align: center;
    background: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (--maxWidth1100) {
    .positionStatusBlock {
        margin-top: var(--offsetMedium);
        max-width: 280px;
    }
}

.positionStatusBlock > *:first-child {
    margin-right: 4px;
}

.iconBlock {
    width: 67px;
    height: 67px;
    background: #FFFFFF;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--offsetSmall);
}

.clinicEmployeesBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.employeesIcons {
    position: relative;
    width: 67px;
    height: 67px;
    background: #FFFFFF;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 50%;
    margin-bottom: var(--offsetSmall);
}

.firstEmployeeIcon {
    position: absolute;
    top: 18px;
    left: 15px;
    z-index: 2;
}

.secondEmployeeIcon {
    position: absolute;
    top: 18px;
    left: 31px;
    z-index: 1;
}

.publishBlock {
    text-align: center;
    margin-top: var(--offsetMedium);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.publishButton {
    width: 230px;
    margin-bottom: var(--offsetSmall);
    margin-top: var(--offsetMedium);
}

.archiveButton {
    composes: publishButton;
}

.editButton {
    width: 230px;
    margin-bottom: var(--offsetMedium);
}

.dashboardButton {
    cursor: pointer;
}

.separator {
    width: 100%;
}

@media (--maxWidth1100) {
    .separator {
        margin-top: var(--offsetLarge);
    }
}

.applyWrapper {
    width: 100%;
    padding: 0 var(--offsetSmall);
    margin-top: var(--offsetMedium);
}

.applyWrapperInner {
    padding-top: var(--offsetMedium);
}

.applicantsBlock {
    width: 100%;
    text-align: center;
    margin-top: var(--offsetMedium);
}

.applicantsLink {
    cursor: pointer;
    margin-top: var(--offsetMedium);
}
