.main {
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 25px;
    background: #FFFFFF;
    padding: 40px 32px;
}

:global(.darkMode) .main {
    background: var(--bgDark);
}

.title {
    font-size: 40px;
    margin-bottom: 70px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
}

.titleThank {
    font-size: 40px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
}

.subTitle {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 64px;
}

.text {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 13px;
}

.list {
    list-style-type: initial;
    margin-left: 16px;
}

.listItem {
    padding-left: 4px;
    font-size: 14px;
    margin-bottom: 13px;

}
