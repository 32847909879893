.fileCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--offsetMedium);
  border-radius: 9px;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  margin-bottom: var(--offsetExtraSmall);
}

.downloadIcon {
  background: transparent;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}

.fileLink {
  position: relative;
  padding-right: 10px;
}

.fileLink:after {
  content: url('/icons/linkGradienIcon.svg');
  position: absolute;
  right: 0;
  top: -12px;
}
