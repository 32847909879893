.main {
    display: grid;
    grid-template-columns: 40% 57% ;
    gap: 3%;
}

@media screen and (--maxWidth1100) {
    .main {
        grid-template-columns: 100%;
    }
}

.bulletWrapper {
    margin-bottom: 20px;
}

.titleWrapper {
    margin-bottom: 48px;
}

@media screen and (--maxWidth1100) {
    .titleWrapper {
        text-align: center;
    }
}

.buttonWrapper {
    display: flex;
}

@media screen and (--maxWidth1100) {
    .buttonWrapper {
        justify-content: center;
        margin-bottom: 50px;
    }
}
