.widget {
  margin: 40px 0;
  overflow-wrap: break-word;
}

@media (--maxWidth900) {
  .widget {
    margin: 30px 0;
  }
}

.addButton {
  width: 200px;
  margin: 30px 0 0;
}

.experienceList {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.experienceItem {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  margin-bottom: 2px;
  position: relative;
}

.editableExperienceItem:hover {
  background: #FFFFFF;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 9px;
  cursor: pointer;
}

.experienceHeading {
  margin-bottom: 10px;
}

@media (--maxWidth900) {
  .experienceHeading {
    padding-right: 50px;
  }
}

.experienceSubheading {
  overflow-wrap: anywhere;
  margin-bottom: 12px;
  margin-right: 40px;
}

.experienceItemSubheader {
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.experienceItemSubheader > * {
  margin-bottom: 8px;
}

.clinicName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.experienceItemAvatar {
  margin-right: 10px;
}

.experienceItemControls {
  top: 20%;
  right: 20px;
  transform: translateY(-50%);
  position: absolute;
  display: none;
  align-items: center;
}

.experienceItemControls > * {
  margin-right: 10px;
}

.experienceItemControls > *:last-child {
  margin-right: 0;
}

@media (--maxWidth900) {
  .experienceItemControls {
    top: 25px;
  }
}

.editableExperienceItem:hover .experienceItemControls {
  display: flex;
}

.bottomSeparator {
  margin-top: 30px;
}
