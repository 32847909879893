.radioButtonBlock {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 121.5%;
  color: var(--redColor);
}

.radioButtonInput {
  left: 0;
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.radioButtonLabel {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-family: var(--primaryFont);
  color: var(--primatyColor);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s ease;
}

.semibold {
  font-weight: 600;
}

.radioButtonCheckmark {
  box-shadow: 0px 1px 2px rgba(0, 8, 83, 0.08), 0px 2px 4px rgba(0, 8, 83, 0.12);
  border-radius: 50%;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-image: var(--colorGradient);
  position: relative;
}

.radioButtonCheckmark::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  box-shadow: inset 0 0 0 8px white;
  transition: 0.2s ease;
}

.radioButtonLabel:hover {
  opacity: 0.8;
}

.radioButtonCheckmark.isActive::after {
  box-shadow: inset 0 0 0 3px white;
}
