.card {
  display: block;
  background: #FFFFFF;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 16px;
}

:global(.darkMode) .card {
  background: #1D426D;
}

.paddingRegular {
  padding: 15px 27px;
}

.paddingMediumEqual {
  padding: 16px;
}

.small {
  padding: 6px;
  border-radius: 8px;
}

.padding0 {
  padding: 0;
}

.borderRadiusSmall {
  border-radius: 6px;
}

.borderRadiusRegular {
  border-radius: 16px;
}
