.dropzone {
  border-radius: 28px;
  background-color: #fff;
  padding: 13px 9px;
  cursor: pointer;
  display: block;
  width: 100%;
}

:global(.darkMode) .dropzone {
  background-color: var(--bgDark);
}

.videoName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: left;
}

.dropzoneIcon {
  height: 75px;
  width: 75px;
  background: linear-gradient(66.36deg, rgba(1, 214, 185, 0.45) 32.49%, rgba(33, 142, 238, 0.45) 72.8%);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.dropzoneInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #fff;
  background:
    linear-gradient(#fff,#fff) padding-box,
    linear-gradient(66.36deg, #01D6B9, #218FEE 49%) border-box;
  border-radius: 16px;
  overflow: hidden;
  min-height: 320px;
}

@media (--maxWidth600) {
  .dropzoneInner {
    padding: var(--offsetMedium) 0;
    min-height: 200px;
  }
}

.dropzoneInnerAutoHeight {
  min-height: auto;
  height: 190px;
  padding: 20px;
}

.dropzoneInnerFilled {
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
}

.progressWrapperBackground {
  background: #FFFFFF;
  padding: 3px 5px;
  border-radius: 8px;
}

.uploadedVideo {
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 100%;
}

.uploadedVideoIsUploaded {
  flex-grow: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trash {
  cursor: pointer;
}

.videoInfo {
  background: #fff;
  display: grid;
  justify-content: space-between;
  padding: 30px 15px;
  grid-template-columns: 1fr 20px;
  gap: 10px;
}

.uploadDescription {
  margin: 0 auto var(--offsetSmall) auto;
  max-width: 315px;
  text-align: center;
  padding: 0 15px;
  color: var(--primatyColorLight);
  font-size: 14px;
  line-height: 1.6;
}

.typeDescription {
  margin-top: var(--offsetSmall);
  color: var(--primatyColorLight);
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  padding: 0 var(--offsetSmall);
}

@media (--maxWidth600) {
  .uploadDescription {
    display: none;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  height: 60px;
  padding: 17px 28px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid var(--primaryColorDark);
  display: block;
}

@media (--maxWidth900) {
  .label {
    height: 40px;
    padding: 8px 20px;
  }

  .label * {
    font-size: 14px;
    font-family: var(--primaryFont);
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.input {
  display: none;
}

.fileName {
  display: flex;
  align-items: center;
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  text-align: left;
  height: 100%;
}

.closeIcon {
  border: 1px solid var(--primatyColorLight);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primatyColorLight);
  font-size: 12px;
  line-height: 1;
  position: absolute;
  right: 31px;
  top: 23px;
}

.fileName:after {
  content: '';
  width: 20px;
  display: inline-block;
  height: 20px;
  background: url('/icons/uploadFileIcon.svg') no-repeat;
  position: absolute;
  top: 6px;
  right: 0;
}

.hasFile:after{
  content: none;
}

.errorLabel {
  border: 1px solid var(--redColor);
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.fileInput {
  display: none;
}

.imgPreview {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 260px;
  width: 100%;
}

.imgPreview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progressWrapper {
  width: 100%;
  padding: 0 25px;
}
