.bookmarkButton {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.1s ease;
  color: white;
  transition: color 0.2s ease, transform 0.1s ease;
  filter: drop-shadow(0px 2px 7px rgba(29, 66, 109, 0.37));
}

.bookmarkButton:hover {
  opacity: 0.8;
}

.bookmarkButton:active {
  transform: translateY(-2px);
}

.bookmarkButtonActive {
  color: var(--primaryColor);
}
