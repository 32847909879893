.pricingList {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 -40px;
  padding: 20px 40px 40px;
}

.pricingList::-webkit-scrollbar {
  display: none;
}

.card {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 15px;
  width: 365px;
  flex: 0 0 365px;
  padding: 50px 40px;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card:not(:last-child) {
  margin-right: 35px;
}

.title {
  margin-bottom: 15px;
  white-space: pre-wrap;
}

.cardBody {
  padding: 35px 0;
  border-top: 2px solid #dde3e9;
  margin-top: 25px;
  flex-grow: 1;
}

.priceBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.priceOriginal {
  margin-right: 15px;
  position: relative;
}

.priceOriginal:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #788EA8;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.priceFinal {
  margin-right: 15px;
}

.discount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-image: linear-gradient(66.36deg, #01d6b9 13.19%, #218fee 79.21%);
  color: white;
  font-weight: 600;
  font-size: 10px;
  line-height: 148.5%;
  box-shadow: 0 2px 4px #c9c7c7;
  margin-left: 3px;
}

:global(.darkMode) .discount {
  box-shadow: none;
}

.cardControls {
  display: flex;
  justify-content: center;
}

.fatureListItem {
  background-image: url('/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 24px;
  padding-left: 40px;
  color: #778ea7;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 14px;
}

@media screen and (--maxWidth900) {
  .fatureListItem {
    font-size: 16px;
  }
}

@media screen and (--maxWidth400) {
  .discount {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
  }

  .pricingList {
    margin: -10px -15px 0;
    padding: 20px 15px 40px;
  }
}

.pricingWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--offsetMedium);
  margin-bottom: var(--offsetMedium);
}

.pricingWrapperTablet {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--offsetMedium);
  margin-bottom: var(--offsetMedium);
}

.priceCard {
  background: #FFFFFF;
  padding: var(--offsetLarge) var(--offsetSmall);
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global(.darkMode) .priceCard {
  background: var(--bgDark);
}

.priceIcon {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--offsetMedium);
}

.priceInfoText {
  text-align: center;
  margin-bottom: 12px;
  flex-grow: 1;
}

.oldPrice:after {
  content: "";
  height: 1px;
  background: #BBC6D3;
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
}

.oldPrice {
  position: relative;
  left: -14px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  color: #BBC6D3;
  padding: 0 3px;
  margin: 0 auto;
}

.oldPriceMobile {
  composes: oldPrice;
  margin-bottom: 3px;
  padding: 0 7px;
}

.priceInfo {
  text-align: center;
  margin-bottom: 40px;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.priceInfoMobile {
  text-align: center;
  margin-bottom: var(--offsetMedium);
}

.priceWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.priceButton {
  display: flex;
  margin: 0 auto;
  width: 80%;
  justify-content: center;
}

.priceButtonMobile {
  composes: priceButton;
  margin-bottom: var(--offsetLarge);
}

.billTypeWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 70px 0 var(--offsetExtraLarge) 0;
  height: 30px;
}

.billTypeWrapper:after,
.billTypeWrapper:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #DDE3E9;
}

.billTypeContent {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (--maxWidth600) {
  .billTypeContent {
    white-space: pre-line;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.billTypeContentText {
  padding: 0 var(--offsetSmall);
}

.billTypeContentSticky {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--offsetMedium) 5px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  z-index: 100;
  transition: .3s ease-in-out;
}

:global(.darkMode) .billTypeContentSticky {
  background: var(--bgDarkTransparent);
}

@media screen and (--maxWidth1100) {
  .billTypeContentSticky {
    top: 50px
  }
}

.priceFeaturesMobile .priceFeaturesColumn:nth-of-type(2n - 1),
.priceFeaturesTablet:nth-of-type(2n - 1) > .priceFeaturesColumn,
.priceFeatures:nth-of-type(2n - 1) > .priceFeaturesColumn {
  background: var(--colorGradientLight);
}

.priceFeatures {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--offsetMedium);
}

.priceFeaturesTablet {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--offsetMedium);
}

.priceFeaturesColumn {
  padding: 15px 18px;
  display: flex;
  align-items: center;
}

.centerNoText {
  width: 100%;
  text-align: center;
  display: block;
}

.tabletPriceWrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--offsetLarge);
}

.featureText {
  padding-left: 30px;
  position: relative;
}

.featureIcon {
  position: absolute;
  left: 0;
  top: -2px;
}

.featureIcon svg {
  width: 16px;
}

.featureEmptyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.featureEmpty {
  display: inline-block;
  height: 2px;
  width: 18px;
  background: var(--colorGradientTranparent);
}

.collapseContent {
  transition: 0.3s ease-out;
}

.mobileFeatureToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--offsetSmall);
}

.mobileFeatureToggleArrow {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url('/icons/arrow.svg') no-repeat center;
  top: 2px;
  margin-left: 10px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.mobileFeatureToggleArrowOpen {
  transform: rotate(180deg);
}

.mobilePricesWrapper {
  display: grid;
  gap: var(--offsetMedium);
}

.billTypeContentTextLeft {
  text-align: right;
}

.featureicon {
  position: absolute;
  left: 0;
  top: 0;
}