.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots:before,
.dots:after {
  display: block;
  content: ' ';
  height: 4px;
  width: 4px;
  background-color: var(--primatyColorLight);
  margin: 2px;
  border-radius: 50%;
}
