.card {
  max-width: 100%;
  width: 280px;
  position: relative;
}

@media (--maxWidth600) {
  .withNumber {
    margin-bottom: 35px;
  }
}

.cardBody {
  box-shadow: var(--cardShadow);
  border-radius: 9px;
  background-color: #fff;
  position: relative;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  will-change: transform, box-shadow;
}

:global(.darkMode) .cardBody {
  background-color: var(--bgDark);
}

.card:hover .cardBody {
  transform: scale(1.07);
  box-shadow: var(--cardShadowMedium);
}

.cardControls {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 2;
}

.cardContainer {
  padding: 15px;
  height: 180px;
  display: flex;
  flex-direction: column;
}

:global(.darkMode) .cardContainer {
  background-color: var(--bgDark);
}

.mediaPlaceholder {
  background-image: linear-gradient(
    270deg,
    #2091ef 0%,
    #1a9de1 31.11%,
    #0dbccd 73.89%,
    #01d6b9 100%
  );
}

.media {
  height: 0;
  padding-bottom: 70%;
  position: relative;
}

.media:hover .mediaElement {
  filter: brightness(0.9);
}

.mediaElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.2s ease;
}

.mediaCaption {
  background: #218FEE;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  top: 10px;
  left: 15px;
  user-select: none;
  z-index: 1;
}

.cardInfo {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.title {
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

.author {
  margin-top: auto;
}

.langInfo {
  margin-left: auto;
}

.cardNumber {
  position: absolute;
  left: -35px;
  bottom: -35px;
  z-index: -1;
  font-size: 120px;
  font-weight: bold;
  font-family: var(--secondaryFont);
  background: linear-gradient(66.36deg, #01d6b9 32.49%, #218fee 72.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
