.preview {
  width: 115px;
  height: 70px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 8px;
  cursor: pointer;
}

@media screen and (--maxWidth600) {
  .preview {
    width: 90px;
    height: 55px;
  }
}

.preview:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(29, 66, 109, 0.60);
}

.preview:before {
  display: block;
  position: absolute;
  content: url("/icons/playVideoIcon.svg");
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview img {
  object-fit: cover;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
