.main {
  max-width: 680px;
  width: 80vw;
  margin: 0 auto;
}

.title {
  padding: 20px 60px 20px var(--offsetLarge);
  border-bottom: 1px solid var(--greyColor);
  word-break: break-word;
  hyphens: auto;
}

.content {
  padding: var(--offsetLarge);
}

.formWrapper {
  position: relative;
}

@media screen and (--maxWidth900) {
  .description {
    padding-top: var(--offsetExtraSmall);
  }
}

.inputWrapper {
  width: 100%;
  margin-bottom: var(--offsetMedium);
}

@media screen and (--maxWidth900) {
  .inputWrapper {
    margin-bottom: var(--offsetSmall);
  }
}

.inputWrapper input::placeholder {
  color: var(--primatyColorLight)
}

.buttons {
  padding: var(--offsetLarge);
  border-top: 1px solid var(--greyColor);
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
}

@media screen and (--maxWidth600) {
  .buttons {
    justify-content: center;
  }
}

@media screen and (--maxWidth400) {
  .buttons {
    flex-direction: column;
  }
}

.cancelButton {
  border: none;
  outline: none;
  padding: 5px 10px;
  font-size: 16px;
  color: var(--primatyColorLight);
  cursor: pointer;
  background: transparent;
  margin-right: var(--offsetMedium);
}

@media screen and (--maxWidth400) {
  .cancelButton {
    margin-right: 0;
    margin-bottom: var(--offsetExtraSmall);
  }
}

.cancelButton:hover {
  filter: brightness(1.4);
}
