.switcherBlock {
  width: 120px;
  height: 40px;
  border-radius: 9px;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.primaryBlock {
  border: 1px solid rgba(31, 66, 109, 0.6);
}

.secondaryBlock {
  border: 1px solid white;
}

.item {
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryFirstItem {
  border-right: 1px solid rgba(31, 66, 109, 0.6);
}

.secondaryFirstItem {
  border-right: 1px solid white;
}

.primaryActiveItem {
  background: #1E426D;
  color: white;
}

.primaryInactiveItem {
  background: transparent;
  color: rgba(31, 66, 109, 0.6);
}

.secondaryActiveItem {
  background: white;
  color: #1D426D;
}

.secondaryInactiveItem {
  background: transparent;
  color: white;
}
