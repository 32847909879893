.documentItem {
    width: 100%;
    padding: var(--offsetMedium);
    background: white;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 9px;
}

.publicationContent {
    display: flex;
}

.icon {
    background: linear-gradient(270deg, rgba(32, 145, 239, 0.1) 0%, rgba(26, 157, 225, 0.1) 31.11%, rgba(13, 188, 205, 0.1) 73.89%, rgba(1, 214, 185, 0.1) 100%);
    min-width: 67px;
    max-width: 67px;
    min-height: 67px;
    max-height: 67px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.description > *:first-child {
    margin-bottom: 8px;
}

.description > *:last-child {
    max-width: 260px;
    overflow-wrap: break-word;
}

@media screen and (--maxWidth900) {
    .description > *:last-child {
        max-width: calc(100vw - 200px);
    }
}
