.bg {
  background-position: center top ;
  background-size: cover;
  background-repeat: no-repeat;
}

.main {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  height: 810px;
}

@media screen and (--maxWidth900) {
  .main {
    padding-top: 50px;
    background-position: bottom center;
  }
}

.topContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.contentWrapper {
  padding: 20px 15px;
  margin: 0 auto;
  max-width: 820px;
  text-align: center;
}

.title {
  margin-bottom: var(--offsetSmall);
}

.innterContent,
.description {
  margin-bottom: var(--offsetLarge);
}

.innterContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
