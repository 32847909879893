.avatar {
  border-radius: 50%;
  background: var(--colorGradient);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.avatarWithOutBackground{
  background: transparent;
}

.white {
  background: #FFFFFF;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tiny {
  width: 18px;
  min-width: 18px;
  height: 18px;
  font-size: 9px;
}


.extraSmall {
  width: 30px;
  min-width: 30px;
  height: 30px;
  font-size: 14px;
}

.small {
  width: 44px;
  min-width: 44px;
  height: 44px;
  font-size: 17px;
}

.regular {
  width: 56px;
  min-width: 56px;
  height: 56px;
  font-size: 20px;
}

.almostMedium {
  width: 67px;
  min-width: 67px;
  height: 67px;
  font-size: 24px;
}

.medium {
  width: 77px;
  min-width: 77px;
  height: 77px;
  font-size: 30px;
}

.large {
  width: 124px;
  min-width: 124px;
  height: 124px;
  font-size: 50px;
}

.gradientText {
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
