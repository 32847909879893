.main {
    display: grid;
    grid-template-columns: 40% 57%;
    gap: 3%;
    margin-bottom: 30px;
}

.mobileMain {
    grid-template-columns: 100%;
    text-align: center;
}

.mobileMain .title {
    margin-bottom: 10px;
}

.title {
    font-size: 40px;
    line-height: 1.3;
    font-family: 'Rubik';
    font-weight: 500;
}

.tag {
    font-size: 32px;
    padding: 6px 16px;
    border-radius: 16px;
    display: inline-flex;
    margin-right: var(--offsetSmall);
    margin-bottom: var(--offsetSmall);
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: 'Rubik';
    min-height: 55px;
}

.rightContent {
    padding-top: 58px;
    padding-left: 10px;
}

.tag span {
    line-height: 1;
}

.studentTag {
    background: rgba(1, 214, 185, 0.28);
    color: #01D6B9;
}

.doctorTag {
    background: rgba(33, 143, 238, 0.28);
    color: #218FEE;
}

.contentCreatorTag {
    background: rgba(157, 82, 255, 0.28);
    color: #9D52FF
}

.clinicTag {
    background: rgba(255, 205, 0, 0.28);
    color: #FFCD00;
}
