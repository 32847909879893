.confirmPopup {
    max-width: 720px;
    width: 80vw;
    padding: 64px 104px;
    text-align: center;
    margin: 0 auto;
}

@media screen and (--maxWidth900) {
    .confirmPopup {
        padding: 64px var(--offsetSmall);
    }
}

.descriptionText,
.mainText {
    margin-bottom: var(--offsetMedium);
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.button {
    margin: var(--offsetExtraSmall);
    max-width: 200px;
    width: 100%;
}
