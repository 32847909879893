.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: -90px auto 0;
  padding: 90px 15px 0;
}

@media (--maxWidth1200) {
  .wrapper {
    padding: 0;
    margin: 0 auto;
  }
}

@media (--maxWidth900) {
  .wrapper {
    padding: 0 15px;
    margin: 0;
  }
}

.aboutMedyoucate {
  text-align: center;
}

.title {
  margin-bottom: 30px;
}

.description {
  margin: 0 auto 100px;
  width: 100%;
  max-width: 810px;
}

@media (--maxWidth900) {
  .description {
    margin-bottom: 50px;
  }
}
