.menuWrapper {
  display: block;
}

@media (--maxWidth1100) {
  .menuWrapperLanding,
  .menuWrapper {
    display:none;
  }
}

.menu {
  display: flex;
  align-items: center;
  padding: 0;
}

.menu li {
  margin: 0 30px 0 0;
  padding: 0;
  list-style: none;
}

.link {
  display: block;
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;
  position: relative;
}

@media (--maxWidth1200) {
  .menu li {
    margin-right: 25px;
  }

  .link * {
    font-size: 14px!important;
  }
}

.withoutLink {
  cursor: pointer;
}

.link:after {
  content: '';
  border-radius: 7px;
  box-shadow: 0 0 0 var(--gradientFallBackColor);
  display: block;
  width: 100%;
  height: 2px;
  background: var(--colorGradient);
  position: absolute;
  top: -27px;
  left: 0;
  opacity: 0;
  transition:  0.2s ease;
}

.dropdownMenu:hover .link:after,
.link:hover:after,
.activeMenu:after  {
  opacity: 1;
  box-shadow: 0 5px 13px var(--gradientFallBackColor);
}

.linkListItem {
  position: relative;
  cursor: pointer;
}

.activeLinkBorder {
  top: -25px;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  position: absolute;
}

.linkItem {
  position: relative;
}

.menuWrapperLanding {
  margin-left: 16%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.menuWrapperLandingDe {
  margin-left: 16%;
}

@media (--maxWidth1100) {
  .menuWrapperLanding {
    display:none;
  }
}

.singInActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  white-space: nowrap;
}

/*dropdownstyles*/
.menuItemWithArrow {
  position: relative;
  padding-right: 30px;
  cursor: pointer;
}

.menuItemWithArrow:before {
  content: " ";
  position: absolute;
  right: 10px;
  top: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  transform: rotate(90deg);
  transition: .2s ease-in-out;
}
:global(.darkMode) .menuItemWithArrow:before {
  border-bottom: 5px solid #FFFFFF;
}

.whiteIcon.menuItemWithArrow:before {
  border-bottom: 5px solid #FFFFFF;
}

@media (--maxWidth1200) {
  .menuItemWithArrow:before {
    top: 6px;
  }
}

.dropdownMenu:hover .menuItemWithArrow:before {
  transform: rotate(0);
}

.dropdownWrapper {
  padding-top: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: .2s ease-in;
}

.dropdownList {
  width: 100%;
  background: #FFFFFF;
  min-width: 190px;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.3);
  border-radius: 5px;
  padding: var(--offsetMedium) 0 var(--offsetLarge) 0;
  white-space: nowrap;
  position: relative;
}

:global(.darkMode) .dropdownList {
  background: var(--bgDark);
}

.dropdownList:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: -8px;
  left: 20%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
}

:global(.darkMode) .dropdownList:after {
  border-bottom: 10px solid var(--bgDark);
}

.dropdownList .dropdownListItem {
  margin-right: 0;
  cursor: pointer;
  padding: var(--offsetSmall) var(--offsetMedium);
  transition: .2s ease-in-out;
}

.dropdownListItem:hover {
  background: var(--primatyColor);
  color: #FFFFFF;
}

.dropdownList .dropdownListItemActive {
  background: var(--colorGradient);
  color: #FFFFFF;
}

.withoutLink:hover .dropdownWrapper {
  visibility: visible;
  opacity: 1;
}

.loginButton {
  border-radius: 35px;
  border: 2px solid #FFFFFF;
  padding: 6px 32px;
  background: transparent;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  font-family: 'Poppins';
  cursor: pointer;
  height: 40px;
}
