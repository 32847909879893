.popup {
  width: 680px;
  padding: 25px 0 35px;
}

@media (--maxWidth900) {
  .popup {
    width: calc(100vw - 30px);
  }
}

.heading {
  padding: 0 35px;
}

.popupContent {
  margin-top: 25px;
}

.textareaBlock {
  margin: 0 35px;
}

.separatorBlock {
  margin-top: 35px;
}

.buttonsBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  margin: 25px 35px 0;
}

@media (--maxWidth900) {
  .buttonsBlock {
    justify-content: space-between;
  }
}

.cancelPseudoButton {
  cursor: pointer;
}
