.offsetNone {
  margin-bottom: 0;
}

.offset10 {
  margin-bottom: 10px;
}

.offset15 {
  margin-bottom: 15px;
}

.offset20 {
  margin-bottom: 20px;
}

.offset30 {
  margin-bottom: 30px;
}

.offset35 {
  margin-bottom: 35px;
}

.offset40 {
  margin-bottom: 40px;
}
