.loadingIndicator {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.indicatorAbsolute {
  position: absolute;
}

.indicatorFixed {
  position: fixed;
}

.loadingContent {
  opacity: 0.3;
  width: 100%;
  min-height: 100px;
  pointer-events: none;
}

.loadingWrapper {
  position: relative;
}

.loadingIndicatorCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.disableContent {
  pointer-events: none;
  opacity: 0.3;
}

.contentLoadingIcon {
  position: absolute;
  left:  50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
