.list {
  display: grid;
  gap: 30px;
  margin: 0 0 35px;
}

.title {
  margin-bottom: 10px;
}

.mediaElement {
  height: auto;
  max-height: 320px;
  width: 100%;
  max-width: 590px;
  object-fit: contain;
}

.card {
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 9px;
  overflow: hidden;
}

:global(.darkMode) .card{
  background-color: var(--bgDark);
}

.cardBody {
  padding: 30px 45px 45px 45px;
}

.description {
  white-space: pre-line;
}

.actions {
  margin-top: 25px;
  display: flex;
}

@media screen and (--maxWidth600) {
  .list {
    flex-direction: column;
    margin: 0 0 20px;
  }

  .card {
    width: 100%;
    margin: 15px 0;
  }

  .cardBody {
    padding: 20px 15px;
  }

  .actions {
    justify-content: center;
  }
}