.widget {
  position: relative;
}

.inputBlock {
  height: 60px;
  background: #FFFFFF;
  border: 1px solid rgba(29, 66, 109, 0.3);
  border-radius: 8px;
  padding: 17px 45px 17px 28px;
  position: relative;
}

.errorInput {
  border-color: var(--redColor)
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

@media (--maxWidth900) {
  .inputBlock {
    width: 100%;
    height: 40px;
    padding: 7px 45px 7px 28px;
  }
}

.inputBlock input::placeholder {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  color: #778EA7;
}

.dropDownIcon {
  position: absolute;
  right: 26px;
  top: 18px;
  opacity: .3;
  cursor: pointer;
  transition: .3s ease-in-out;
}

@media (--maxWidth900) {
  .dropDownIcon {
    top: 10px;
  }
}

.dropDownIconReversed {
  transform: rotate(-180deg);
  opacity: 1
}

.inputField {
  border: none;
  outline: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primatyColor);
  width: 100%;
}

.selectBlockWrapper {
  z-index: 30;
  background: #FFFFFF;
  border: 1px solid rgba(29, 66, 109, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  top: 70px;
  overflow: hidden;
  width: 100%;
}

@media (--maxWidth900) {
  .selectBlockWrapper {
    width: 100%;
    top: 50px;
  }
}

.selectBlock {
  z-index: 30;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 300px;
}

@media (--maxWidth600) {
  .selectBlock {
    width: 100%;
    top: 110px
  }
}

.selectItem {
  width: 100%;
  padding: 6px 32px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primatyColor);
  font-weight: 600;
  cursor: pointer;
}

.requiredDot {
  position: absolute;
  color: var(--redColor);
  font-size: 14px;
  top: 23px;
  left: 16px;
}

@media (--maxWidth900) {
  .requiredDot {
    top: 11px;
  }
}

.noResult {
  padding: var(--offsetSmall);
  text-align: center;
}

.selectIcon {
  min-width: 14px;
  display: flex;
}

.skillName {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (--maxWidth600) {
  .skillName {
    max-width: calc(100vw - 140px);
  }
}

.selectItemContent {
  display: flex;
}

.selectItemContent > * {
  margin-right: 10px !important;
}

.selectItemContent > *:last-child {
  margin-right: 0 !important;
}

.categoriesLabels {
  z-index: 20;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (--maxWidth600) {
  .categoriesLabels {
    width: 100%;
  }
}

.categoryContainer {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  max-width: 100%;
}

.categoryContent {
  border: 2px solid rgba(29, 66, 109, 0.6);
  padding: 7px 14px;
  border-radius: 8px;
  display: flex;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: rgba(29, 66, 109, 0.6);
}

.categoryContent > span {
  max-width: 450px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (--maxWidth600) {
  .categoryContent > span {
    max-width: calc(100vw - 118px);
  }
}

.closeIconBlock {
  min-height: 11px;
  min-width: 11px;
  margin-left: 15px;
  cursor: pointer;
}
