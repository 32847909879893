.mobileMenu {
  display: none;
}

@media screen and (--maxWidth1100) {
  .mobileMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    position: relative;
  }
}

.menuIcon {
  cursor: pointer;
}

.mobileMenu svg {
  animation: iconAppear 0.3s ease-in-out;
}

@keyframes iconAppear {
  from { opacity: 0}

  to { opacity: 1 }
}

.sideBarOverlay {
  width: 25px;
  position: fixed;
  animation: iconAppear 0.3s ease-in-out;
  height: calc(100vh - 51px);
  top: 51px;
  left: 0;
  background: rgba(29, 66, 109, 0.5);
}

.sideBar {
  animation: iconAppear 0.3s ease-in-out;
  position: fixed;
  right: 0;
  top: 51px;
  bottom: 0;
  width: calc(100vw - 25px);
  overflow: auto;
  z-index: 90;
  background: white;
}

.sideBarGradient {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  overflow-y: scroll;
  background: url('/images/signup/bg-left.jpg')
    top left
    no-repeat
    padding-box
    content-box,
    url('/images/signup/bg-bottom-right.jpg')
    bottom right
    no-repeat
    padding-box
    content-box;
  display: flex;
  flex-direction: column;
}

:global(.darkMode) .sideBarGradient {
  background: var(--bgDark);
}

.searchInputBlock {
  padding: 25px 20px 30px;
  width: 100%;
}

.searchBlockContent {
  position: relative;
}

.searchInput {
  width: 100%;
  height: 40px;
  padding: 8px 24px;
  border-radius: 32px;
  z-index: 415;
  position: relative;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--primatyColor);
  outline: none;
}

.searchInputOverlay::after {
  border-radius: 32px;
  content: '';
  background: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 410;
}

.searchInputOverlay::before {
  border-radius: 32px;
  content: '';
  background-image: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: 405;
}

.searchInput::placeholder {
  color: var(--primatyColorLight);
}

.searchIcon {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 12px;
  right: 24px;
  z-index: 425;
}

.searchIcon button {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
}

.userWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.avatarWrapper {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--colorGradient);
  margin-bottom: 15px;
}

.avatar {
  border-radius: 50%;
  width: 105px;
  height: 105px;
}

.userFullName {
  text-align: center;
  overflow-wrap: break-word;
  width: calc(100vw - 85px);
}

.userStatus {
  margin: 12px auto 35px;
  text-align: center;
  overflow-wrap: break-word;
  width: calc(100vw - 85px);
}

.separatorBlock {
  padding: 0 20px;
  width: 100%;
}

.footer {
  margin: 15px 30px 100px;
  display: flex;
  flex-direction: column;
}

.supportBlock {
  cursor: pointer;
}

.signOutBlock {
  cursor: pointer;
  margin-top: 30px;
}

.signInBlock {
  cursor: pointer;
}

.jobWidget {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.jobWidget > div {
  width: 100%;
}

.footerWithOpenJobsWidget {
  margin-bottom: var(--offsetSmall);
}
