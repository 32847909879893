.review {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background: #FFFFFF;
    box-shadow: 0 20px 30px 1px rgba(29, 66, 109, 0.25);
    border-radius: 30px;
    max-width: 900px;
    width: 100%;
    height: auto;
    min-height: 335px;
    margin: 50px auto;
    overflow: hidden;
}

:global(.darkMode) .review {
    background: var(--bgDark);
}

@media screen and (--maxWidth900) {
    .review {
        margin: 50px auto;
        min-height: auto;
    }
}

.carouselItem {
    padding: 0 60px;
}

@media screen and (--maxWidth900) {
    .carouselItem {
        padding: 0;
    }
}

.textInfo {
    padding: 72px 32px 20px 32px;
    width: 100%;
}

@media screen and (--maxWidth900) {
    .textInfo {
        padding: 20px;
    }
}

.reviewText {
    margin-bottom: 32px;
}

.name {
    text-transform: uppercase;
}

.image {
    width: 250px;
    object-fit: cover;
}

@media screen and (--maxWidth600) {
    .image {
        display: none;
    }
}

.name,
.position {
    font-weight: 400;
    line-height: 1.5;
    color: #1D426D;
}

:global(.darkMode) .name,
:global(.darkMode) .position {
    color: var(--textSubDark);
}

.carouselWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    margin: 0 auto;
}

@media screen and (--maxWidth900) {
    .carouselWrapper {
        padding: 0;
    }
}

.userInfo {
    display: flex;
    align-items: center;
}

.mobileImage {
    display: none;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-right: var(--offsetMedium);
    object-fit: cover;
}

@media screen and (--maxWidth600) {
    .mobileImage {
        display: block;
    }
}

@media screen and (--maxWidth400) {
    .mobileImage {
        width: 60px;
        height: 60px;
    }
}

.userReviewText {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
}

@media screen and (--maxWidth600) {
    .userReviewText {
        font-size: 12px;
    }
}
