.main {
  width: 100%;
  margin: 5px auto;
  border-radius: 40px;
  background-color: #E5E5E5;
  height: 4px;
  position: relative;
}

.line {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 40px;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(270deg, #01D6B9 -12.74%, #218FEE 68.03%);
}

.oneThird {
  width: 33.33%;
}

.twoThirds {
  width: 66.66%;
}

.oneQuarter {
  width: 25%;
}

.twoQuarters {
  width: 50%;
}

.thirdQuarter {
  width: 75%;
}

.full {
  width: 100%;
}
