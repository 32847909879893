.main {
  display: grid;
  grid-template-columns: 360px 1fr;
  gap: 60px;
}

.sidebar280 {
  grid-template-columns: 280px 1fr;
}

@media (--maxWidth1100) {
  .main {
    display: block;
  }
}

.sideBarMain {
  position: sticky;
  top: 100px;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--offsetMedium);
  margin-bottom: var(--offsetMedium);
}

.sideBarCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sideBarCell:first-child {
  width: 100%;
}

@media (--maxWidth1100) {
  .sideBarMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sideBarCell:first-child {
    justify-content: center;
    text-align: center;
  }
}
