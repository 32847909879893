.wrapper {
  max-width: 1025px;
  margin: 40px auto 0;
  text-align: center;
}

.subtitle {
  margin-top: 30px;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 20px 60px 0;
}

@media (--maxWidth750) {
  .list {
    display: inline-grid;
    grid-template-columns: 1fr;
  }
}

@media (--maxWidth600) {
  .list {
    margin: 20px;
  }
}

.listItem {
  padding: 25px 25px 25px 50px;
  background-image: url('/icons/playIcon.svg');
  background-size: 29px 26px;
  background-repeat: no-repeat;
  background-position: left center;
  color: #778ea7;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
}

:global(.darkMode) .listItem {
  color: #FFFFFF;
}

@media (--maxWidth600) {
  .wrapper {
    margin-top: 20px;
  }
}
