.main {
  margin-bottom: var(--offsetMedium);
}

.videoWrapper {
  width: 100%;
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
}

.video {
  width: 100%;
  height: auto;
}

.action {
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 4px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
}

.videoAction {
  position: absolute;
  right: var(--offsetSmall);
  top: var(--offsetSmall);
  z-index: 1;
}

.videoPlayButton {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.imageWrapper {
  width: 100%;
  text-align: center;
  position: relative;
}

.zoomImage {
  position: absolute;
  right: var(--offsetMedium);
  top: var(--offsetMedium);
}

.imageWrapper img {
  max-width: 100%;
  height: auto;
}

.mediaWrapper {
  margin-bottom: var(--offsetExtraSmall);
}

.vimeoVideo {
  position: relative;
  padding-bottom: 42.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.vimeoVideo iframe, .vimeoVideo object, .vimeoVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
