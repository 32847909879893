.container {
  width: 100%;
  max-width: 1205px;
  min-width: 320px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
}

.widePaddingOnDesktop {
  max-width: 1260px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}

@media screen and (--maxWidth900) {
  .container {
    padding: 0 15px;
  }
}
