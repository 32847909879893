.actionWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.main {
  overflow: hidden;
}

.sliderWrapper {
  padding: 0 20px;
  width: 100%;
  overflow: hidden;
  margin-right: -25px;
  max-width: 100%;
}

@media (--maxWidth1100) {
  .sliderWrapper {
    margin: 0 15px;
  }
}

.sectionOffset {
  margin: 0 0 0 max(0px, calc(50vw - 1260px / 2));
}

.cellMobile {
  align-items: center;
  justify-content: center;
}

.action {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  padding: 10px 15px;
}

.cssActionMobile * {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  text-align: center!important;
}

@media (--maxWidth1100) {
  .action {
    padding: 10px 50px;
  }
}

@media (--maxWidth900) {
  .action {
    padding: 10px 30px;
  }
}

@media (--maxWidth600) {
  .action {
    padding: 30px 15px;
  }
}

.cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
