.footer {
  background: var(--colorGradient);
  padding: 75px 0 20px 0;
}

.footerSponsors {
  margin-bottom: 20px;
}

.mainlinks {
  margin-bottom: var(--offsetLarge);
}

.contactsLinks {
  margin-bottom: var(--offsetSmall);
}

.infoWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}

@media (--maxWidth600) {
  .infoWrapper {
    grid-row-gap: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.firstColumn {
  max-width: 400px;
  margin-right: 40px;
}

@media (--maxWidth900) {
  .firstColumn {
    max-width: 100%;
    grid-column: 1/3;
    gap: 20px;
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 760px) {
  .firstColumn {
    grid-template-columns: 1fr;
  }
}

.footerLogo {
  margin-bottom: 17px;
}

.companyText {
  margin-bottom: 24px;
}

.listTitle {
  margin: 0 0 25px 0;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.list li {
  margin-bottom: 16px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (--maxWidth600) {
  .contactInfo {
    width: 100%;
  }
}

.socialLinks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -36px 0 0;
}

.socialLinks li {
  margin-right: 16px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialLinks li:last-child {
  margin-right: 0;
}

.footerBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 60px;
  justify-content: space-between;
  gap: 20px;
}

@media (--maxWidth1300) {
  .languageWrapper {
    margin-right: 60px;
  }
}

@media (--maxWidth600) {
  .footerBottom {
    flex-direction: column-reverse;
    row-gap: 0;
  }

  .languageWrapper {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.subscribeWrapper {
  position: relative;
  border: 1px solid white;
  border-radius: 32px;
  height: 52px;
  display: flex;
  justify-content: stretch;
  padding: 3px;
  margin-bottom: 15px;
}

.subscribeWrapperDark {
  border-color: rgba(119, 142, 167, 0.37);
  padding: 5px;
}

.subscribeWrapperError {
  border-color: var(--redColor);
}

.error {
  margin: 4px 0 10px 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.subscribeIcon {
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
}

@media (--maxWidth400) {
  .subscribeIcon {
    display: none;
  }
}

.subscribeIconDark svg path {
  fill: #778EA7;
}

.subscribeInput {
  padding: 10px;
  background: transparent;
  flex-grow: 1;
  border: 0;
  color: #fff;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  border: none;
  font-family: 'Poppins';
}

.subscribeInput:focus,
.subscribeInput:focus-visible {
  background: transparent;
  border: 0;
  outline: none;
}

.subscribeInput::placeholder {
  color: white;
  font-size: 16px;
  font-family: 'Poppins';
}

.subscribeInputDark {
  color: #778EA7;
}

:global(.darkMode) .subscribeInputDark {
  color: #fff;
}

.subscribeInputDark::placeholder {
    color: #778EA7;
    font-weight: 400;
    font-family: 'Poppins';
}

@media (--maxWidth600) {
  .subscribeInputDark::placeholder {
    font-size: 14px;
  }
}

.subscribeButton {
  border-radius: 32px;
  border: none;
  background: white;
  color: var(--primatyColor);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--primaryFont);
  padding: 12px 16px;
  white-space: nowrap;
  line-height: 1;
  cursor: pointer;
}

.subscribeButton:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

@media (--maxWidth600) {
  .subscribeButton {
    font-size: 12px;
  }
}

.subscribeButtonDark {
    background: #1D426D;
    color: #fff;
    min-width: 140px;
    font-size: 16px;
    height: 40px;
}

:global(.darkMode) .subscribeButtonDark {
    background: #FFFFFF;
    color: var(--primatyColor);
}

@media (--maxWidth600) {
  .subscribeButtonDark {
    font-size: 12px;
    min-width: auto;
  }
}

.disabledButton {
    opacity: 0.5;
    cursor: no-drop;
}

.subscribeCheckboxWrapper input {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  position: absolute;
}

.subscribeCheckboxWrapper {
  position: relative;
  display: flex;
  color: #FFFFFF;
}

.subscribeCheckbox:after {
  opacity: 0;
  content: '';
  width: 7px;
  height: 3px;
  border-right: 2px solid #01d6b9;
  border-top: 2px solid #01d6b9;
  transform: rotate(132deg);
  position: absolute;
  top: 3px;
  left: 2px;
}

.subscribeCheckbox {
  content: '';
  margin-top: 3px;
  margin-right: 8px;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  background: #FFFFFF;
  position: relative;
  min-height: 15px;
  min-width: 15px;
}

.subscribeCheckboxWrapper input:checked ~ .subscribeCheckbox:after{
  opacity: 1;
}

.subscribeCheckboxDark {
  border: 1px solid #1D426D;
}

.subscribeCheckboxDark:after {
  opacity: 0;
  content: '';
  width: 7px;
  height: 3px;
  border-right: 2px solid #FFFFFF;
  border-top: 2px solid #FFFFFF;
  transform: rotate(132deg);
  position: absolute;
  top: 3px;
  left: 2px;
}

.subscribeCheckboxWrapper input:checked ~ .subscribeCheckboxDark{
  background: #1D426D;
}
