.trustedSection {
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 15px;
  padding: 45px 10px 35px;
  overflow: hidden;
  margin-bottom: 130px;
}

.backgroundOff {
  background: transparent;
  box-shadow: none;
}

.imagesInline {
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.trustedTitle {
  max-width: 1205px;
  margin: 0 auto;
}

.trustedBox {
  padding: 10px 30px;
  min-height: 60px;
}

.trustedBox img {
  max-height: 50px;
  width: auto;
}

.trustedImage {
  display: block;
  object-fit: contain;
}

.carouselWrapper {
  padding: 25px 0;
}

@media screen and (--maxWidth900) {
  .trustedTitle {
    padding: 0 20px;
  }

  .trustedSection {
    margin-bottom: 70px;
  }
}

.standAloneTrasted {
  width: 100%;
  margin-bottom: -60px;
}

.standAloneTrasted.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
