.header {
  padding: 16px 0;
  background: transparent;
  box-shadow: 0 -6px 15px transparent;
  transition: 0.2s ease-in-out;
  z-index: 300;
  position: fixed;
  width: 100%;
}

.headerMargin {
  width: 100%;
  margin-bottom: 72px;
}

@media (--maxWidth1100) {
  .headerMargin {
    margin-bottom: 51px;
  }
}

@media (--maxWidth1100) {
  .header {
    background-color: #fff;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    padding: 8px 0;
  }
  :global(.darkMode) .header{
    background-color: var(--mainPageDark);
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    padding: 8px 0;
  }
}

.headerSticky {
  background: #FFFFFF;
  box-shadow: 0 -6px 15px var(--gradientFallBackColor);
}

:global(.darkMode) .headerSticky{
  background: var(--mainPageDark);
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: flex-start;
  width: 165px;
  min-width: 165px;
  margin-right: 20px;
}

.logo svg {
  width: 100%;
}

@media (--maxWidth900) {
  .logo{
    width: 115px;
  }
}

.addCourse {
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
  z-index: 401;
}

.searchIcon {
  margin-right: 20px;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 401;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--colorGradient);
  position: relative;
}

.profileAvatar {
  border-radius: 40px;
}

.profileWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileWrapper:hover .profileDesktopMenu {
  display: block;
}

.profileDesktopMenu {
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  z-index: 400;
}

.singInWrapper {
  display: flex;
  align-items: center;
}

.singInButton {
  margin-left: 20px;
}

.howToWrapper {
  display: grid;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: auto auto;
  margin-right: var(--offsetMedium);
}

.mobileSelectOption {
  width: 32px;
  height: 32px;
  position: relative;
  background: transparent;
  color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  outline: none;
  appearance: none;
}

.mobileSelectOptionIconWrapper{
  position: relative;
  margin-right: 20px;
}

.current {
  margin-left: 10px;
  content: url("/icons/dropdownIndicatorGradient.svg");
  transition: .2s ease-in;
}

.whiteCurret {
  margin-left: 10px;
  content: url("/icons/whiteCurret.svg");
  transition: .2s ease-in;
}

.addWithHover {
  margin-right: 20px;
}

.buttonWithHoverEffect {
  display: flex;
  align-items: center;
}

.addWithHover:hover .current {
  transform: rotate(-180deg);
}

.disableSelectOptionIconWrapper,
.disbledAddCourse,
.addWithHoverDisable {
  pointer-events: none;
  opacity: 0.4;
}
