.subMenu {
  padding-top: var(--offsetMedium);
}

:global(.darkMode) .subMenu * {
  color: var(--textSubDark) !important;
}

.subMenuItem:not(:last-child) {
  padding-bottom: var(--offsetSmall);
}
