.label {
  height: 60px;
  padding: 17px 28px 17px 28px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid var(--primaryColorDark);
  display: block;
  cursor: pointer;
}

.small .label {
  height: 48px;
  padding: 8px 20px;
}

.small .label * {
  font-family: var(--primaryFont);
}

@media (--maxWidth900) {
  .label {
    height: 40px;
    padding: 8px 20px;
  }

  .label * {
    font-family: var(--primaryFont);
  }
}

.inputWrapper {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.input {
  display: none;
}

.fileName {
  display: flex;
  align-items: center;
  padding-right: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  text-align: left;
  height: 100%;
}

.closeIcon {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
  background: #FFFFFF;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.small .closeIcon {
  top: 21px;
  right: 0;
  padding: 3px;
}

@media (--maxWidth900) {
  .closeIcon {
    top: 21px;
    right: 0;
    padding: 3px;
  }
}

.fileName:after {
  content: '';
  width: 20px;
  display: inline-block;
  height: 20px;
  background: url('/icons/uploadFileIcon.svg') no-repeat;
  position: absolute;
  top: 6px;
  right: 0;
}

.hasFile:after{
  content: none;
}

.description {
  font-size: 12px;
  margin: var(--offsetExtraSmall) 0;
  color: var(--primatyColorLight);
}

.errorLabel {
  border: 1px solid var(--redColor);
}

.disableInput {
  opacity: 0.5;
  pointer-events: none;
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.simpleFileInputError {
  margin: 4px 0 0 0;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.requiredIcon {
  position: absolute;
  color: red;
  font-size: 14px;
  top: 23px;
  left: 16px;
}
.small .requiredIcon {
  top: 13px;
  left: 11px;
}

@media (--maxWidth900) {
  .requiredIcon {
    top: 13px;
    left: 11px;
  }
}
