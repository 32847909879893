.main {
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 35px;
    max-width: 496px;
    width: 100%;
    margin: 0 auto;
}

.form {
    position: relative;
}

.input {
    height: 60px;
    padding:0 140px 0 24px;
    display: block;
    font-size: 18px;
    width: 100%;
    outline: none;
    color: var(--primatyColor);
    font-family: 'Poppins', sans-serif;
}

.deinput {
    padding-right: 190px;
}

.input::placeholder {
    color: #778EA7;
    font-weight: 300;
}

.button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    outline: none;
    min-width: 130px;
}

@media screen and (--maxWidth460) {
    .input {
        font-size: 14px;
    }
    .button * {
        font-size: 14px;
    }
}
