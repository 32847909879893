.primary {
  color: var(--primatyColor);
}

:global(.darkMode) .primary {
  color: var(--textMainDark);
}

.secondary {
  color: var(--primaryColorMuted);
}

:global(.darkMode) .secondary {
  color: var(--textSubDark);
}

.grey {
  color: var(--primatyColorLight);
}

:global(.darkMode) .grey {
  color: var(--textSubDark);
}

.white {
  color: white;
}

.muted {
  color: var(--mutedColor);
}

.red {
  color: var(--redColor);
}

.gradient {
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue {
  color: #218FEE;
}

.orange {
  color: #FFAC30;
}

.thinWeight {
  font-weight: 300;
}

.normalWeight {
  font-weight: normal;
}

.boldWeight {
  font-weight: 600;
}

.mediumBold {
  font-weight: 500;
}

.size44 {
  font-size: 44px;
}

.size40 {
  font-size: 40px;
}

.size36 {
  font-size: 36px;
}

@media (--maxWidth900) {
  .size36 {
    font-size: 24px;
  }
}

.size35 {
  font-size: 35px;
}

.size32 {
  font-size: 32px;
}

.size26 {
  font-size: 26px;
}

.size28 {
  font-size: 28px;
}

.size24 {
  font-size: 24px;
}

@media (--maxWidth900) {
  .size32 {
    font-size: 24px;
  }
}

.size24 {
  font-size: 24px;
}

.size20 {
  font-size: 20px;
}

@media (--maxWidth900) {
  .size20 {
    font-size: 17px;
  }
}

.size18 {
  font-size: 18px;
}

@media (--maxWidth900) {
  .size18 {
    font-size: 16px;
  }
}

.size17 {
  font-size: 17px;
}

.size16 {
  font-size: 16px;
}

@media (--maxWidth900) {
  .size17 {
    font-size: 14px;
  }
}

.size14 {
  font-size: 14px;
}

.size12 {
  font-size: 12px;
}

.size11 {
  font-size: 11px;
}

.rubik {
  font-family: 'Rubik', sans-serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.lineHeight1-3 {
  line-height: 1.3;
}

.lineHeight1-2 {
  line-height: 1.2;
}

.lineHeight1 {
  line-height: 1.1;
}

.lineHeight1-4 {
  line-height: 1.4;
}

.lineHeight1-6 {
  line-height: 1.6;
}

.lineHeight21 {
  line-height: 21px;
}

.lineHeight24 {
  line-height: 24px;
}

.lineHeight2 {
  line-height: 2;
}

.lineHeight32 {
  line-height: 32px;
}

.textUppercase {
  text-transform: uppercase;
}

.textLowercase {
  text-transform: lowercase;
}

.textCapitalize {
  text-transform: capitalize;
}

.textInitialCase {
  text-transform: initial;
}

.oneLine {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.displayBlock {
  display: block;
}

.displayInline {
  display: inline;
}

.displayInlineBlock {
  display: inline-block;
}
