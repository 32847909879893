.detailsItem {
    display: grid;
    grid-template-columns: 16px auto;
    gap: var(--offsetExtraSmall);
    align-items: center;
    margin-bottom: var(--offsetExtraSmall);
    line-height: 1.5;
}

.detailsItemText {
    font-size: 16px;
}

@media (--maxWidth600) {
    .detailsItem svg {
        width: 12px !important;
    }

    .detailsItemText {
        font-size: 12px;
    }
}
