.widget {
  position: relative;
}

.inputBlock {
  height: 60px;
  background: #FFFFFF;
  border: 1px solid rgba(29, 66, 109, 0.3);
  border-radius: 8px;
  padding: 17px 45px 17px 28px;
  position: relative;
  display: flex;
  align-items: center;
}

.small {
  height: 48px;
}

.width390pxInputBlock {
  width: 390px
}

.width100prInputBlock {
  width: 100%;
}

.inputWithError {
  border: 1px solid var(--redColor);
}

@media (--maxWidth900) {
  .inputBlock {
    width: 100%;
    height: 40px;
    padding: 7px 45px 7px 28px;
  }
}

.inputBlock input::placeholder {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  color: var(--primatyColor);
}

.inputField {
  border: none;
  outline: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primatyColor);
  width: 100%;
}

.inputField:disabled {
  background: none;
}

.inputFlag {
  margin-right: 10px;
}

.dropDownIcon {
  position: absolute;
  right: 26px;
  top: 18px;
  cursor: pointer;
  opacity: 0.3;
  transition: .3s ease-in-out;
}

.small .dropDownIcon {
  top: 15px;
  right: 17px;
}

@media (--maxWidth900) {
  .dropDownIcon {
    top: 10px;
  }
}

.dropDownIconReversed {
  opacity: 1;
  transform: rotate(180deg);
}

.selectBlockWrapper {
  z-index: 30;
  background: #FFFFFF;
  border: 1px solid rgba(29, 66, 109, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  top: 70px;
  overflow: hidden;
  min-height: 30px;
}

.small ~ .selectBlockWrapper {
  top: 50px;
}

.width390pxSelectBlock {
  width: 390px;
}

.width100prSelectBlock {
  width: 100%;
}

@media (--maxWidth900) {
  .selectBlockWrapper {
    top: 50px;
  }
}

@media (--maxWidth600) {
  .selectBlockWrapper {
    width: 100%;
    top: 50px;
  }
}

.selectBlock {
  z-index: 30;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 300px;
}

@media (--maxWidth600) {
  .selectBlock {
    width: 100%;
    top: 110px
  }
}

.selectItem {
  width: 100%;
  padding: 6px 32px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primatyColor);
  font-weight: 600;
  cursor: pointer;
}

.selectIcon {
  min-width: 14px;
  display: flex;
}

.skillName {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (--maxWidth600) {
  .skillName {
    max-width: calc(100vw - 140px);
  }
}

.selectItemContent {
  display: flex;
}

.selectItemContent > * {
  margin-right: 10px !important;
}

.selectItemContent > *:last-child {
  margin-right: 0 !important;
}

.flagIcon {
  margin-right: 4px;
}

.requiredIcon {
  position: absolute;
  color: red;
  font-size: 14px;
  top: 23px;
  left: 16px;
}

.small .requiredIcon {
  top: 15px;
}

@media (--maxWidth900) {
  .requiredIcon {
    top: 13px;
    left: 11px;
  }
}

.noResultsItem {
  padding: 20px;
}

.errorField {
  margin: 4px 0 0 28px;
}
