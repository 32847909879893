.wordsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 25px 0;
}

.wordsButton {
  display: flex;
  align-items: center;
  border: none;
  background: #778EA7;
  border-radius: 8px;
  outline: none;
  color: #FFFFFF;
  height: 40px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 14px;
  font-family: var(--primaryFont);
  transition: 0.2s ease;
  will-change: transform;
  user-select: none;
  font-weight: 600;
  line-height: 1;
}

.wordsButton:hover {
  background-color: #FFFFFF;
  color: #778EA7;
}

.wordsButton:active {
  transform: scale(1.02);
}

@media screen and (--maxWidth900) {
  .wordsList {
    justify-content: center;
  }
}
