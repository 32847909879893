.buttonBlock {
  position: relative;
  transition: 0.2s ease;
  will-change: filter;
  text-align: center;
}

.fullWidthContent {
  width: 100%;
}

.fitContent {
  width: fit-content;
}

.children {
  line-height: 1.6;
}

.buttonBlock:hover {
  filter: brightness(1.1);
}

.buttonBlockLoading {
  pointer-events: none;
}

.buttonBlockDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  border: none;
  width: 100%;
  padding: 0 20px;
  user-select: none;
  line-height: 1.4;
}

.loadingWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.smallHeight {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 35px;
}

.secondary.smallHeight {
  height: 44px;
}

.mediumHeight {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  border-radius: 35px;
}

.secondary.mediumHeight {
  height: 54px;
}

.normalHeight {
  height: 60px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
}

.secondary.normalHeight {
  height: 64px;
}

.regularHeight {
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  padding: 0 50px;
}

.secondary.regularHeight {
  height: 49px;
}

@media (--maxWidth900) {
  .normalHeight {
    height: 42px;
    font-size: 14px;
  }
}

.bigHeight {
  height: 100px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 16px;
}

@media (--maxWidth900) {
  .bigHeight {
    height: 75px;
    font-size: 16px;
  }
}

.primary {
  background: linear-gradient(66.36deg, #01d6b9 32.49%, #218fee 72.8%);
  color: white;
}

.secondary {
  position: relative;
  border-radius: 35px;
  border: 1px solid transparent;
  background: -webkit-linear-gradient(66.36deg, #01d6b9 32.49%, #218fee 72.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 20;
}

.secondary * {
  background: inherit;
  white-space: nowrap;
}

.secondary::after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' height='100%25' width='100%25' style='width: calc(100%25 - 1px); height: calc(100%25 - 1px)' rx='20' ry='20' stroke-width='1' fill='transparent' stroke='url(%23Gradient)' /%3E%3Cdefs%3E%3ClinearGradient id='Gradient' x1='61.4706' y1='75.122' x2='104.786' y2='22.9853' gradientUnits='userSpaceOnUse' %3E%3Cstop offset='0.303391' stop-color='%2301D6B9' /%3E%3Cstop offset='0.904511' stop-color='%23218FEE' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 10;
}

.borderNormal {
  border-width: 1px !important;
}

.borderBold {
  border-width: 2px !important;
}

.borderBold.secondary:after {
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' height='100%25' width='100%25' style='width: calc(100%25 - 2px); height: calc(100%25 - 2px)' rx='20' ry='20' stroke-width='2' fill='transparent' stroke='url(%23Gradient)' /%3E%3Cdefs%3E%3ClinearGradient id='Gradient' x1='61.4706' y1='75.122' x2='104.786' y2='22.9853' gradientUnits='userSpaceOnUse' %3E%3Cstop offset='0.303391' stop-color='%2301D6B9' /%3E%3Cstop offset='0.904511' stop-color='%23218FEE' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.red {
  background: white;
  position: relative;
  border-radius: 35px;
  border: 1px solid var(--redColor);
  color: var(--redColor);
}

.redFill {
  background: var(--redColor);
  position: relative;
  border-radius: 35px;
  border: 1px solid var(--redColor);
  color: #FFFFFF;
}

.yellow {
  background: white;
  position: relative;
  border-radius: 35px;
  border: 1px solid var(--yellowColor);
  color: var(--yellowColor);
}

.white {
  background: #ffffff;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  font-weight: normal;
  color: #1d426d;
}

:global(.darkMode) .white * {
  color: #1d426d!important;
}

.whiteSecondary {
  background: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}

.white:hover {
  background: linear-gradient(270deg, rgba(32, 145, 239, 0.1) 0%, rgba(26, 157, 225, 0.1) 31.11%, rgba(13, 188, 205, 0.1) 73.89%, rgba(1, 214, 185, 0.1) 100%);
  color: #1d426d;
}

.whiteOutline {
  background: transparent;
  border: 1px solid #FFFFFF;
}

.withoutBorder {
  border: none;
}

.arrowUp:after,
.arrowDown:after,
.arrowRight:after,
.arrowLeft:after {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 12px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-bottom: 8px solid white;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  transition: 0.3s ease-in-out;
}

.arrowDown:after {
  transform: translate(-50%, -50%) rotate(180deg);
}

.button.arrowUp,
.button.arrowDown,
.button.arrowLeft,
.button.arrowRight {
  padding: 0 50px 0 28px;
}

.unstyledGradient {
  height: auto;
  background: transparent;
  border: none;
}
