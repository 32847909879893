.card {
  display: flex;
  border: 1px solid #eeeeee;
  background-color: #fff;
  box-shadow: var(--cardShadow);
  border-radius: 30px;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  width: 570px;
  position: relative;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, box-shadow;
}

:global(.darkMode) .card {
  background-color: var(--bgDark);
}

.card:hover {
  box-shadow: var(--cardShadowMedium);
  transform: scale(1.07);
}

.content {
  padding: 40px 15px 40px 40px;
  flex: 1 0 0;
}

.cardContainer {
  padding: 15px;
}

.media {
  flex: 0 0 300px;
  height: 500px;
  position: relative;
}

.mediaElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.2s ease;
}

.author {
  word-break: break-word;
  margin-bottom: 4px;
}

.title {
  word-break: break-word;
  margin-bottom: 4px;
}

.title * {
  hyphens: auto;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}

.actions {
  display: flex;
  margin-top: 25px;
}

@media screen and (--maxWidth600) {
  .card {
    width: 100%;
    border-radius: 9px;
    margin: 0 auto;
  }

  .media {
    flex: 0 0 38%;
    height: 100%;
    min-height: 350px;
    position: relative;
  }

  .content {
    padding: 40px 15px;
  }
}
