.inputClass {
  height: 60px !important;
  width: 100% !important;
  border-color: var(--primaryColorDark) !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-family: var(--primaryFont) !important;
  padding-left: 100px !important;
  color: var(--primatyColor) !important;
}

.inputClass::placeholder {
  color: var(--primatyColorLight) !important;
  font-family: var(--primaryFont);
  font-size: 16px;
}

@media (--maxWidth900) {
  .inputClass {
    height: 40px !important;
  }
}

.buttonClass {
  background: #FFFFFF !important;
  width: 86px;
  border-right: none !important;
}

.buttonClass:after {
  content: "";
  height: 50%;
  width: 1px;
  background: var(--primaryColorDark);
  position: absolute;
  right: -1px;
  top: 25%;
}

/*select button*/
.buttonClass > div {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*flag*/
.buttonClass > div > div {
  transform: scale(1.2);
  margin-left: -8px;
}

.buttonClass > div > div > div {
  margin-left: 4px;
}
