.widget {
    position: relative;
    width: calc(100% + 30px);
    left: -15px;
    padding: 15px;
    background: linear-gradient(270deg, rgba(32, 145, 239, 0.1) 0%, rgba(26, 157, 225, 0.1) 31.11%, rgba(13, 188, 205, 0.1) 73.89%, rgba(1, 214, 185, 0.1) 100%);
}


.content {
    max-width: 1205px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.leftWidgetPart {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
}

.firstRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

@media (--maxWidth1100) {
    .firstRow {
        flex-direction: column;
        align-items: baseline;
    }
}

.firstRow > *:first-child {
    margin-right: 5px;
}

@media (--maxWidth1100) {
    .firstRow > *:first-child {
        margin-bottom: 5px;
    }
}

.rightWidgetPart {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightWidgetPart > * {
    margin-right: 24px;
}

.rightWidgetPart > *:last-child {
    margin-right: 0;
}

.dashboardButton {
    position: relative;
    cursor: pointer;
}
