.section {
  text-align: center;
}

.content {
  margin-top: 15px;
}

.faqList {
  margin-top: 50px;
}

.faqListItem {
  margin-bottom: 15px;
  text-align: left;
}

.faqListItemContent {
  padding: 0 40px;
}

@media screen and (--maxWidth900) {
  .content {
    margin-top: 30px;
  }

  .faqListItemContent {
    padding: 0 15px;
  }
}
