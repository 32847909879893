.customSearch {
  position: relative;
}
.customSearchButton * {
  font-size: 16px;
}

.customSearchButton button {
  min-width: 122px;
}

.customSearchInput {
  outline: none;
  background: #ffffff;
  border: 1px solid rgba(29, 66, 109, 0.3);
  box-sizing: border-box;
  border-radius: 32px;
  width: 100%;
  height: 54px;
  padding: 5px 130px 5px 25px;
  font-family: var(--primaryFont);
  font-size: 16px;
  color: var(--primatyColor);
  appearance: none;
  -webkit-appearance: none;
}

.decustomSearchInput {
  padding-right: 140px;
}

input.inputField::placeholder {
  color: red;
}

.customSearchInput::-webkit-search-cancel-button {
  display: none;
}

.customSearchButton {
  position: absolute;
  right: 0;
  top: 0;
  margin: 7px;
}

.customSearchButton button {
  padding: 0;
  width: 100px;
}

@media screen and (--maxWidth900) {
  .customSearchInput {
    height: 46px;
    font-size: 16px;
  }

  .customSearchButton {
    margin: 4px;
  }

  .customSearchButton button {
    height: 38px;
    font-size: 14px;
  }
}

@media screen and (--maxWidth600) {
  .customSearchInput {
    font-size: 16px;
  }
}
