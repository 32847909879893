.radioButtonBlock {
  display: flex;
  line-height: 100%;
}

.iconBlock {
  margin-right: 16px;
}

.label {
  font-weight: 600;
  font-size: 16px;
  color: var(--primatyColor);
  padding-top: 3px;
}
