.section {
  overflow: hidden;
}

.fullWidth .title {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 20px;
}

.onLineWithDots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}

.container {
  max-width: 1260px;
  padding: 80px 40px 60px;
  margin: 0 auto;
  position: relative;
}

.fullWidth {
  max-width: none;
}

.title {
  margin-bottom: 0;
}

.centeredTitle {
  text-align: center;
}

.controls {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.controls button {
  min-width: 110px;
  height: 40px;
  font-size: 16px;
}

@media screen and (--maxWidth900) {
  .container {
    padding: 35px 15px;
  }

  .fullWidth .title {
    margin: 0 auto;
    padding: 0;
  }

  .title br {
    display: none;
  }

  .controls {
    margin-top: 15px;
  }

  .controls button {
    font-size: 14px;
  }
}

@media screen and (--maxWidth600) {
  .controls {
    position: absolute;
    left: 15px;
    bottom: 50px;
    display: inline-flex;
    justify-content: flex-start;
    margin-top: 0;
  }

  .controls button {
    min-width: 130px;
    height: 38px;
    font-size: 12px;
  }
}

@media screen and (--maxWidth400) {
  .controls {
    width: 100%;
    position: static;
    justify-content: center;
    align-items: center;
  }
}
