.vStack {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.hStack {
  display: flex;
  flex-direction: row;
  gap: 16px;
}