.wrapper {

  color: #FFFFFF;
  background: var(--colorGradient);
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 200px;
}

.smallHeight {
  height: 127px;
  font-size: 32px;
}

.regularHeight {
  height: 300px;
}

.lightBackground {
  background: var(--colorGradientLight);
  color: var(--primatyColor);
}
