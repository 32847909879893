.widget {
  position: relative;
}

.requiredIcon {
  position: absolute;
  color: red;
  font-size: 14px;
  top: 20px;
  left: 16px;
  z-index: 1;
}

@media screen and (--maxWidth900) {
  .requiredIcon {
    left: 13px;
    top: 9px;
  }
}

.error {
  position: absolute;
  margin: 2px 0 2px 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 121.5%;
  color: var(--redColor);
  text-align: left;
}

.root {
  width: 100%;
  background: white;
  border-radius: 4px;
}

.errorRoot {
  composes: root;
}

.errorRoot fieldset {
  border-color: red !important;
}

.errorRoot:hover fieldset {
  border-color: red !important;
}

.labelRoot {
  padding-left: 14px !important;
  color: var(--primatyColorLight) !important;
  font-family: var(--primaryFont) !important;
}

@media (--maxWidth900) {
  .labelRoot {
    padding-left: 8px !important;
  }
}

.labelFocused {
  padding: 0 3px 0 0 !important;
  background: #FFFFFF !important;
  display: inline-block !important;
}

.input {
  padding-left: 28px !important;
  font-family: var(--primaryFont) !important;
}

.input::placeholder {
  font-family: var(--primaryFont) !important;
  color: var(--primatyColorLight) !important;
}

@media (--maxWidth900) {
  .input {
    padding-left: 22px !important;
  }
}

.root button {
  background: url('/icons/gradientCalendar.svg') no-repeat center center;
  background-size: 20px 22px;
}

.root svg {
  opacity: 0;
  position: relative;
}
