.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  cursor: pointer;
  font-size: 25px;
  color: var(--primatyColor);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
}

.arrowPrev {
  transform: rotate(180deg);
}

.arrow svg {
  fill: var(--primatyColor);
}

.activeArrow:first-of-type {
  margin-right: 15px;
}

.activeArrow:last-of-type {
  margin-left: 15px;
}

.pageNumber {
  line-height: 1;
  border: none;
  background-color: transparent;
  height: 35px;
  width: 35px;
  flex: 0 0 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  cursor: pointer;
  font-size: 18px;
  color: var(--primaryColorMuted);
  user-select: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.activePage {
  background: linear-gradient(66.36deg, #01d6b9 32.49%, #218fee 72.8%);
  color: white;
  outline: none;
}
