.swiperContainer {
  padding: 40px 0 60px;
}

.smallOffset {
  padding: 25px 0;
}

.tinyOffset {
  padding: 15px 0;
}

.offsetYNone {
  padding-left: 0;
  padding-right: 0;
}

.offsetYSmall {
  padding-left: var(--offsetSmall);
  padding-right: var(--offsetSmall);
}

.swiperButton {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 20;
  background: linear-gradient(66.36deg, #01d6b9 32.49%, #218fee 72.8%);
  color: white;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  user-select: none;
  opacity: .5;
}

.swiperButton:hover {
  filter: brightness(1.1);
  opacity: 1;
}

.swiperButtonPrev {
  left: 0;
}

.swiperButtonNext {
  right: 0;
}

/* SWIPER HIGHLIGHTED TYPE */
.swiperContainer.highlighted [class*='swiper-slide'] {
  opacity: 0.2;
}

.swiperContainer.highlighted [class*='swiper-slide-active'] {
  opacity: 1;
  pointer-events: all;
}

.swiperContainer.highlighted .swiperButtonPrev,
.swiperContainer.highlighted .swiperButtonNext {
  opacity: 0.5;
}

.swiperContainer.highlighted:hover .swiperButtonPrev,
.swiperContainer.highlighted:hover .swiperButtonNext {
  opacity: 1;
}

.swiperContainer [class*='swiper-button-lock'] {
  display: none;
}

.swiperContainer.navigationBottom {
  padding: 20px 0 70px 10px
}

.swiperContainer.navigationBottom .swiperButton {
  top: auto;
  bottom: 0;
  transform: none;
  left: 6px;
}

.arrowsOffset3 .swiperButton {
  top: 47%;
}

.swiperContainer.navigationBottom .swiperButtonNext {
  left: 75px;
}

.swiperContainer.navigationFloating .swiperButtonPrev {
  left: 20%;
}

.swiperContainer.navigationFloating .swiperButtonNext {
  right: 20%;
}

.slide {
  width: auto;
  height: auto;
  transition: opacity 0.4s ease;
}

.swiperContainer.slideGap10 {
  margin: 0 -10px;
}

.swiperContainer.slideGap20 {
  margin: 0 -20px;
}

.swiperContainer.slideGap30 {
  margin: 0 -30px;
}

.swiperContainer.slideGap40 {
  margin: 0 -40px;
}

.swiperContainer.slideGapWithCounter {
  margin: 0 -25px;
}

.slide.slideGap10 {
  padding: 0 10px;
}

.slide.slideGap20 {
  padding: 0 20px;
}

.slide.slideGap30 {
  padding: 0 30px;
}

.slide.slideGap40 {
  padding: 0 40px;
}

.slide.slideGapWithCounter {
  padding: 0 20px
}

.swiperPagination {
  display: none;
}

.withPagination .swiperPagination {
  display: block;
}

.swiperBullet[class*='swiper-pagination-bullet'] {
  height: 11px;
  width: 11px;
}

.swiperBullet[class*='swiper-pagination-bullet-active'] {
  background-color: transparent;
  background-image: var(--colorGradient);
}

/* VERTICAL CARD STYLES */
.verticalCardsEffect {
  padding: 60px 12%;
}

.verticalCardsEffect [class*='swiper-slide'] [class*='slideInfo'],
.verticalCardsEffect [class*='swiper-slide'] img {
  opacity: 0;
  transition: 0.2s ease;
  pointer-events: none;
  user-select: none;
}

.verticalCardsEffect [class*='swiper-slide-active'] [class*='slideInfo'],
.verticalCardsEffect [class*='swiper-slide-active'] img {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
}

.verticalCardsEffect .swiperButton[class*='swiper-button-disabled'] {
  opacity: 0.5;
  cursor: not-allowed;
}

.swiperContainer.verticalCardsEffect .swiperPagination {
  display: block;
}

.withAutoplay [class*='swiper-wrapper'] {
  transition-timing-function: linear !important;
  align-items: center;
}

/* VERTICAL CARD STYLES END*/

@media (--maxWidth900) {
  .swiperContainer {
    margin: 0 -15px 25px!important;
  }

  .swiperContainer .swiperButton {
    top: auto;
    bottom: 0;
    transform: none;
    left: 20px;
  }

  .swiperContainer .swiperButtonPrev {
    left: 20px !important;
    right: auto !important;
  }

  .swiperContainer .swiperButtonNext {
    left: 80px !important;
    right: auto !important;
  }

  .swiperContainer.highlighted [class*='swiper-slide'],
  .swiperContainer.highlighted .swiperButtonPrev,
  .swiperContainer.highlighted .swiperButtonNext {
    opacity: 1;
  }

  .slide.slideGapWithCounter {
    padding: 0 0 0 40px;
  }

  .verticalCardsEffect {
    padding: 60px 0;
    margin: 0 !important;
  }
}

@media (--maxWidth1100) {
  .resetMobile {
    margin: 0;
  }
}

@media (--maxWidth600) {
  .swiperButton {
    display: none!important;
  }

  .swiperContainer.navigationBottom {
    padding: 40px 0 50px;
  }

  .swiperPagination {
    display: block;
    bottom: 0px !important;
    text-align: center;
    padding: 0 25px;
  }

  .paginationMobileToRight .swiperPagination{
    text-align: right;
    position: absolute;
    width: auto;
    right: 0 !important;
    left: initial !important;
  }

  .paginationMobileToRight .swiperContainer.navigationBottom{
    padding-bottom: 90px;
  }

  .verticalCardsEffect {
    padding: 30px 0 60px;
  }

  .verticalCardsEffect .swiperButton {
    display: flex;
  }
}

@media screen and (--maxWidth400) {
  .paginationMobileToRight .swiperPagination{
    width: 100%;
    text-align: center;
  }

  .paginationMobileToRight .swiperContainer.navigationBottom{
    padding-bottom: 90px;
  }
}
