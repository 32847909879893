.main {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(221, 227, 233, 0.94);
    min-height: 34px;
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 50;
    font-size: 12px;
    padding: 5px 43px 5px 5px;
    text-align: center;
}

:global(.darkMode) .main {
    background: var(--bgDarkTransparent);
}

@media (--maxWidth1100) {
    .main {
        top: 51px;
    }
}

.main a {
    background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    font-weight: bold;
    margin-left: 4px;
}

.content {
    line-height: 1.6;
}

.closeButton {
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.closeButton svg {
    display: block;
}

.warningIcon {
    margin-right: var(--offsetExtraSmall);
    display: flex;
    align-items: center;
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
}
