.title {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--offsetExtraSmall);
  align-items: center;
}

.toggleIcon {
  background-image: url('/icons/dropDownIndicator.svg');
  background-repeat: no-repeat;
  flex: 0 0 12px;
  width: 10px;
  height: 7px;
  background-size: 100%;
  margin-left: auto;
  transition: 0.2s ease-out;
}

.isOpen {
  transform: rotate(-180deg);
}

.howToMenuWrapper {
  position: absolute;
  top: 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 5px;
  width: 88vw;
  max-width: 320px;
  right: 20px;
}

:global(.darkMode) .howToMenuWrapper {
  background: var(--bgDark);
}

.howToMenu {
  max-height: 50vh;
  overflow: auto;
  padding: 0 var(--offsetSmall);
}

.howToMenuWrapper:before {
  content: '';
  position: absolute;
  top: -5px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: #fff;
  right: 65px;
}

:global(.darkMode) .howToMenuWrapper:before {
  background: var(--bgDark);
}
