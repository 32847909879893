.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
  row-gap: 90px;
  margin-bottom: 130px;
}

@media (--maxWidth900) {
  .main {
    column-gap: 50px;
    row-gap: 55px;
    margin-bottom: 90px;
  }
}

@media (--maxWidth600) {
  .main {
    margin-bottom: 40px;
    grid-template-columns: 1fr;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--primatyColor);
  line-height: 1.6;
  font-weight: 600;
}

:global(.darkMode) .title {
  color: var(--textMainDark);
}

.titleIcon {
  margin-right: 30px;
}

@media (--maxWidth600) {
  .titleIcon {
    margin-right: 24px;
    width: 28px;
    min-width: 28px;
  }

  .titleIcon img {
    width: 100%;
  }
}

.list {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

@media (--maxWidth600) {
  .list {
    margin-left: 10px;
  }
}

.listItem {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: var(--primatyColorLight);
}

:global(.darkMode) .listItem {
  color: var(--textSubDark);
}

.listItem:before {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url('/icons/playIcon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 2px;
}

.greyIcon:before {
  background-image: url('/icons/greyBullet.svg');
}
