.widget {
  margin: 15px 0 0;
}

.linkWithCursor {
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: column;
}

.link {
  margin: 15px 0;
  padding: 0 30px;
  position: relative;
}

.clickAbleLink {
  cursor: pointer;
  margin: 15px 0;
  padding: 0 30px;
  position: relative;
}

.activeLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 25px;
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  box-shadow: 5px 0 13px rgba(0, 214, 185, 0.7);
}

.separatorBlock {
  padding: 15px 20px;
  width: 100%;
}

.subLinks {
  margin-top: 15px;
  padding-left: 45px;
}

.subLinksItem {
  margin: 30px 0 30px 0;
}

.subLinksItemActive {
  font-weight: bold;
}
