.popupContainer {
  width: 780px;
}

.error {
  padding: 10px 35px 25px;
  text-align: center;
}

@media (--maxWidth900) {
  .popupContainer {
    width: 90vw;
  }
}

.heading {
  padding: 25px 35px 0;
}

.buttonsBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 35px 30px;
}

@media (--maxWidth900) {
  .buttonsBlock {
    flex-direction: column-reverse;
  }
}

.secondaryButtonsBlock {
  display: flex;
}

.secondaryButtonsBlock > *:first-child {
  margin-right: 16px;
}

.fileInput {
  display: none;
}

.zoomLabel {
  margin: 40px 35px 0;
}

@media (--maxWidth900) {
  .zoomLabel {
    margin: 20px 35px 0;
  }
}

.sliderBlock {
  width: 360px;
  margin: 20px 35px 40px;
}

@media (--maxWidth900) {
  .sliderBlock {
    width: calc(90vw - 60px);
    margin: 10px 35px 15px;
  }
}

.cropperBlock {
  position: relative;
  width: 100%;
  height: 200px;
}

@media (--maxWidth900) {
  .cropperBlock {
    height: 160px;
  }
}

.sliderRail {
  background-color: rgba(29, 66, 109, 0.6) !important;
}

.deleteImageBlock {
  cursor: pointer;
}

@media (--maxWidth900) {
  .deleteImageBlock {
    margin-top: 35px;
  }
}

.sliderThumb {
  width: 12px !important;
  height: 12px !important;
  border: 6px solid white !important;
  box-shadow: 0px 1px 2px rgba(0, 8, 83, 0.08), 0px 2px 4px rgba(0, 8, 83, 0.12);
  box-sizing: content-box !important;
  background-color: #2091EF !important;
}
