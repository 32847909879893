.widget {
  display: flex;
  border-bottom: 2px solid rgba(29, 66, 109, 0.1);;
  width: 880px;
}

@media (--maxWidth900) {
  .widget {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    padding-bottom: 18px;
  }
}

.linkItem {
  margin: 0 20px;
  white-space: nowrap;
  padding-bottom: 20px;
  cursor: pointer;
  position: relative;
}

.linkItem:last-child {
  margin: 0 0 0 20px;
}

@media (--maxWidth900) {
  .linkItem {
    margin: 0 20px;
  }
}

.linkItemHighlight {
  height: 2px;
  width: 100%;
  background: linear-gradient(66.36deg, #01D6B9 32.49%, #218FEE 72.8%);
  position: absolute;
  bottom: -2px;
}
