.main {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action {
  opacity: 0;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.deleteIcon:after {
  content: url('/icons/trash-red.svg');
  color: #fff
}

.zoomIcon:after {
  content: url('/icons/zoomIconWhite.svg');
}

.main:hover .action {
  opacity: 1;
  background: var(--primatyColorLight);
  pointer-events: initial;
}

.main img{
  width: 100%;
  height: auto;
}

.loadingImage {
  background-image: var(--colorGradientLight);
  min-height: 100px;
}
