.main {
  width: 100%;
  margin: 0 auto;
  height: 4px;
  position: relative;
}

.line {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  background: var(--colorGradient);
}
