.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.textarea {
  padding: 17px 28px;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid var(--primaryColorDark);
  color: var(--primatyColor);
  border-radius: 8px;
  font-family: var(--primaryFont);
  outline: none;
  resize: vertical;
  width: 100%;
}

@media (--maxWidth900) {
  .textarea {
    padding: 8px 20px;
  }
}

.textarea::placeholder {
  color: var(--primatyColorLight);
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
}

.inputAreaError {
  border: 1px solid var(--redColor);
}

.maxLengthCounter {
  position: absolute;
  bottom: -20px;
  right: 12px;
}
