.inputBlock {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputAreaDisabled {
  border: 1px solid rgba(29, 66, 109, 0.05);
}

.inputAreaDisabled input {
  background: white;
  color: var(--primatyColorLight);
}

.width150 {
  max-width: 150px;
  width: 100%;
}

.width390 {
  max-width: 390px;
  width: 100%;
}

.widthFull {
  width: 100%;
}

.inputArea {
  height: 60px;
  padding: 17px 28px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid var(--primaryColorDark);
}

.small {
  height: 48px;
  padding: 11px 28px;
}

.inputAreaError {
  border-color: var(--redColor);
}

.inputAreaCommon {
  border-color: var(--primaryColorDark);
}

@media (--maxWidth900) {
  .inputArea {
    height: 40px;
    padding: 8px 20px;
  }
}

.inputField {
  border: none;
  outline: none;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--primatyColor);
  width: 100%;
}

.inputField::placeholder {
  color: var(--primatyColorLight);
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 121.5%;
  color: var(--redColor);
}

.resetIcon {
  background: #FFFFFF;
  padding: 10px;
  position: absolute;
  top: 30px;
  right: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}

.small ~ .resetIcon {
  top: 23px;
  padding: 1px;
}

@media (--maxWidth900) {
  .resetIcon {
    top: 21px;
    right: 0;
    padding: 3px;
  }
}

.requiredIcon {
  position: absolute;
  color: red;
  font-size: 14px;
  top: 23px;
  left: 16px;
}

.small .requiredIcon {
  top: 15px;
}

@media (--maxWidth900) {
  .requiredIcon {
    top: 13px;
    left: 11px;
  }
}
