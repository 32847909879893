.infoSection {
  background-image: var(--colorGradient);
  padding: 42px 50px;
  max-width: 1200px;
  margin: 30px auto 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  flex: 0 1 765px;
  min-width: 0px;
  word-break: break-word;
}

.actions {
  padding-left: 15px;
  white-space: nowrap;
  display: grid;
  gap: var(--offsetSmall);
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainButton {
  border: 2px solid #FFFFFF;
  border-radius: 35px;
  padding: 8px 30px;
}

.mainButtonWhite {
  background: #FFFFFF;
}

.title {
  margin-bottom: 10px;
}

.text a {
  color: var(--primatyColor);
  font-weight: 600;
}

@media screen and (--maxWidth900) {
  .infoSection {
    flex-direction: column;
    padding: 30px
  }

  .content {
    flex: 0 1 auto;
  }

  .actions {
    margin-top: var(--offsetSmall);
    padding-left: 0;
  }
}
