.main {
    background: linear-gradient(270deg, #9BCEF8 0%, #98D3F1 31.11%, #92E1E8 73.89%, #8DECE0 100%);
    padding: 40px 32px;
    border-radius: 25px;
    overflow: hidden;
}

.title {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    margin-bottom: 40px;
}

:global(.darkMode) .title{
    color: var(--primatyColor);
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 40px;
    line-height: 1.6;
}
:global(.darkMode) .description{
    color: var(--primatyColor);
}


.button {
    padding: 8px 24px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 35px;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    border: 0;
    cursor: pointer;
}

.button span {
    background: linear-gradient(66.36deg, #01D6B9 13.19%, #218FEE 79.21%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
