.wrapper {
  min-height: 100vh;
  background: url('/images/signup/bg-top-right.jpg')
    top right
    no-repeat
    fixed
    padding-box
    content-box,
    url('/images/signup/bg-left.jpg')
    top 30% left
    no-repeat
    fixed
    padding-box
    content-box,
    url('/images/signup/bg-bottom-right.jpg')
    bottom right
    no-repeat
    fixed
    padding-box
    content-box
}

@media screen and (--maxWidth900) {
  .wrapper {
    background:
      url('/images/signup/bg-left.jpg')
      top left
      no-repeat
      fixed
      padding-box
      content-box,
      url('/images/signup/bg-bottom-right.jpg')
      bottom right
      no-repeat
      fixed
      padding-box
      content-box
  }
}

.disableBackground {
  background: #FFFFFF;
}

@media screen and (--maxWidth600) {
  .wrapper {
    background: none;
  }
}

/*DARK MODE*/
:global(.darkMode) .wrapper {
  background-color: var(--mainPageDark);
  background: url('/images/darkbg.png');
  background-size: cover;
  background-position: center top;
  background-repeat: repeat;
}