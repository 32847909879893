.content {
  margin: 30px 0;
}

.featuresList {
  display: grid;
  gap: 35px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  margin-top: 80px;
}

.featureCard {
  display: inline-flex;
  align-items: center;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 15px;
  height: 105px;
  position: relative;
}

.featureCardIco {
  margin-right: 20px;
  width: 50px;
}

.slide {
  background: linear-gradient(
    66.36deg,
    rgba(1, 214, 186, 0.25) 13.19%,
    rgba(33, 142, 238, 0.25) 79.21%
  );
  border-radius: 30px;
}

.slideMedia {
  border-radius: 30px;
}

.slideMedia img {
  width: 100%;
  height: auto;
}

.vimeoVideo {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.vimeoVideo iframe, .vimeoVideo object, .vimeoVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.videoWrapper {
  padding: 35px;
}

.video {
  border-radius: 30px;
  overflow: hidden;
}

.slideInfo {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.textSlider {
  margin: -40px 0 -60px;
}

.textSliderTitle {
  margin-bottom: 15px;
}

@media (--maxWidth900) {
  .slide {
    padding: 20px;
  }

  .textSlider {
    margin: -50px 0 -90px;
  }
}

@media (--maxWidth600) {
  .slide {
    padding: 0;
  }

  .textSlider {
    margin: -50px 0 -70px;
  }
}
