.title {
    margin-bottom: var(--offsetExtraLarge);
}

.mobilePositions {
    display: flex;
    height: 100%;
}

@media (--maxWidth900) {
    .title {
        margin-bottom: 0;
    }
}
.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.positionsList {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--offsetLarge);
    margin-bottom: var(--offsetLarge);;
}

.mobilePosition {
    margin: auto;
}

.otherPositionsWrapper {
    margin-top: 72px;
    margin-bottom: var(--offsetExtraLarge);
}
