.imagesView {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -16px;
}

.image {
  width: 100%;
  max-width: 170px;
  margin-right: var(--offsetSmall);
  margin-bottom: var(--offsetSmall);
  display: flex;
  align-items: stretch;
}

.sliderWidth {
  width: 170px;
}

.videoWrapper {
  margin-bottom: var(--offsetSmall);
}

.linkImage {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  height: 100%;
}

.showMore {
  padding: var(--offsetSmall);
  background: #F4F6F8;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 125px;
  text-align: center;
  margin-bottom: var(--offsetSmall);
  width: 100%;
  cursor: pointer;
  border: none;
}

.imagesSlider {
  display: none;
}

@media screen and (--maxWidth750) {
  .imagesView {
    display: none;
  }

  .imagesSlider {
    display: block;
  }
}
