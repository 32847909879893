.main {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;
}


@media screen and (--maxWidth900) {
    .main {
        grid-template-columns: 100%;
        gap: var(--offsetSmall);
    }
}
