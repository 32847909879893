.main {
    display: grid;
    grid-template-columns: 50% 50%;
}

@media screen and (--maxWidth900) {
    .main {
        grid-template-columns: 100%;
    }
}

.leftContent {
    padding-top: 70px;
    padding-right: 60px;
}

@media screen and (--maxWidth900) {
    .leftContent {
        padding-top: 0;
        padding-right: 0;
    }
}

.title {
    margin-bottom: var(--offsetLarge);
}

.description {
    margin-bottom: var(--offsetExtraLarge);
}

:global(.darkMode) .description * {
    color: var(--textSubDark);
}

.rightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.buttonWrapper {
    display: flex;
    margin-bottom: 40px;
}

.image {
    width: 100%;
}

@media screen and (--maxWidth900) {
    .image {
        max-width: 300px;
    }
}

.separatorWrapper {
    margin-bottom: var(--offsetExtraLarge);
}

.countList {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--offsetExtraLarge);
    max-width: 372px;
}

.buttonContent {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    align-items: center;
}
