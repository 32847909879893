.section {
  overflow: hidden;
  padding: 60px 0;
  background: rgba(248, 249, 251, 0.8);
  backdrop-filter: blur(81px);
  background-image: url('/images/lightBgTop.png'), url('/images/gradBgBot.png');
  background-position: left top, left bottom;
  background-repeat: no-repeat;
}

:global(.darkMode) .section {
  background: var(--mainPageDark)
}

.container {
  display: flex;
  align-items: center;
  padding: 0 20px 0 40px;
  margin: 0 0 0 max(0px, calc(50vw - 1260px / 2));
}

.content {
  flex: 0 0 415px;
  margin-right: 110px;
}

.controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  width: 100%;
  position: relative;
  z-index: 4;
}

.landingButtons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.controls button {
  min-width: 100px;
  font-size: 16px;
}

.logo {
  display: inline-block;
  width: 165px;
  height: 30px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 8px;
}

.slider {
  flex: 1 0 0;
  min-width: 0;
}

@media screen and (--maxWidth900) {
  .landingButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0;
  }

  .landingButtons a {
    margin: var(--offsetExtraSmall);
  }

  .section {
    padding: 35px 0;
  }

  .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }

  .content {
    flex: 0 0 100%;
    margin: 0;
  }

  .slider {
    max-width: 100%;
  }

  .logo {
    width: 130px;
  }

  .title br {
    display: none;
  }

  .controls {
    justify-content: center;
  }

  .controls button {
    min-width: 110px;
    height: 38px;
    font-size: 14px;
  }

  .logo {
    margin-bottom: 10px;
  }
}

@media screen and (--maxWidth600) {
  .controls button {
    min-width: 130px;
    font-size: 12px;
  }

  .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
  }

  .seeAllLink {
    position: absolute;
    left: 15px;
    bottom: 30px;
  }
}

@media screen and (--maxWidth400) {
  .seeAllLink {
    position: static;
  }
}
