.container {
  margin-top: 65px;
  position: relative;
}

.widget {
  width: 280px;
  background: #FFFFFF;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
  border-radius: 16px;
  padding: 25px 0 35px;
}

:global(.darkMode) .widget {
  background: var(--bgDark);
}

.widgetHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 25px 25px;
}

.userAvatar {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--colorGradient);
}

.widgetBody {
  margin: 25px 35px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.widgetFooter {
  padding: 25px 35px 0;
}

.arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: 15px;
  background: #FFFFFF;
  transform: rotateY(0deg) rotate(45deg);
}
:global(.darkMode) .arrow {
  background: var(--bgDark);
}

.profileAvatar {
  border-radius: 50%;
}

.fullNameBlock {
  overflow-wrap: break-word;
  max-width: calc(100% - 45px);
}
