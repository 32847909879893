.popup {
    width: 680px;
    padding: 64px 88px 35px;
    text-align: center;
    position: relative;
    background-image: url("public/images/jobPortal/confetti.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
}

@media (--maxWidth900) {
    .popup {
        width: 90vw;
    }
}

.content {
    z-index: 502;
    position: relative;
}

.heading {
    margin-bottom: var(--offsetMedium);
}

.buttonBlock {
    margin-top: var(--offsetLarge);
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    min-width: 108px;
}

.confettiBackground {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -126px;
    overflow: hidden;
    left: 0;
    z-index: 501;
}

@media (--maxWidth900) {
    .confettiBackground {
        min-height: 181px;
        bottom: 0;
    }
}
