.tag {
  padding: 10px 20px;
  border: 2px solid currentColor;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}

.whiteColor {
  color: #FFFFFF;
}

.primaryColor {
  color: var(--primatyColor);
}
