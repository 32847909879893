.checkboxBlock {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 121.5%;
  color: var(--redColor);
}

.checkboxInput {
  left: 0;
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkboxLabel {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  font-family: var(--primaryFont);
  color: var(--primatyColor);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  transition: 0.2s ease;
  max-width: 100%;
}

.semibold {
  font-weight: 600;
}

.checkboxLabel::before {
  content: '';
  border-radius: 3px;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px var(--primatyColorLight);
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-color: white;
  background-image: url('/icons/checkIco.svg'), var(--colorGradient);
  background-size: 0%, 0%;
}

.checkboxLabel:not(.isActive):hover {
  opacity: 0.8;
}

.checkboxLabel.isActive::before {
  box-shadow: inset 0 0 0 0 var(--primatyColorLight);
  background-size: 10px auto, 100%;
}

.oneLine {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
