.header {
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.header:hover {
  opacity: 0.8;
}

.isDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.toggleIcon {
  background-image: url('/icons/dropDownIndicator.svg');
  background-repeat: no-repeat;
  flex: 0 0 12px;
  width: 10px;
  height: 7px;
  background-size: 100%;
  margin-left: auto;
  transition: 0.2s ease-out;
}

.toggleIconWithCircle {
  background-image: url('/icons/cicledArrow.svg');
  width: 33px;
  height: 33px;
  flex: 0 0 33px;
  margin-top: 5px;
}

:global(.darkMode) .toggleIconWithCircle {
  background-image: url('/icons/circleArrowDark.svg');
}

.disableDefaultArrow {
  background-image: none;
}

.isOpen {
  transform: rotate(-180deg);
}

.content {
  transition: 0.3s ease-out;
}

.icon {
  margin-right: 18px;
  width: 45px;
  max-width: 45px;
  min-width: 45px;
  display: flex;
  justify-content: center;
}

.icon img {
  max-width: 100%;
}

.contentWrapper {
  margin-top: 20px;
  margin-left: 20px;
}

.collapseWrapper {
  margin-bottom: 45px;
}

.collapseTitle {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (--maxWidth600) {
  .collapseTitle * {
    font-size: 20px!important;
  }
}
