.mainWrapper {
  margin-bottom: 5px;
}

.mainWrapper:last-child {
  margin-bottom: 0;
}

.header {
  padding: 12px 18px 12px 30px;
  background: var(--greyColor);
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 68px;
}

:global(.darkMode) .header {
  background: var(--bgDark);
}

.headerWithoutHeader {
  cursor: default;
}

.dragIcon {
  position: absolute;
  left: var(--offsetMedium);
  top: 50%;
  transform: translate(-50%, -50%);
  padding-right: 10px;
}

.arrowIndicator {
  cursor: pointer;
  margin: 0 10px;
  width: 22px;
  height: 22px;
  min-width: 22px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowIndicator svg {
  transition: .3s;
}

.arrowIndicatorOpen svg{
  transform: rotate(-90deg);
}

.isRounded {
  border-radius: 16px;
}

@media (--maxWidth600) {
  .header {
    padding: 16px 13px 16px 38px;
    height: auto;
  }
}

.content {
  transition: 0.3s ease-in-out;
}

.contentInner,
.default {
  padding-top: 23px;
}

@media screen and (--maxWidth600) {
  .default {
    padding-top: 16px;
  }
}

.noPadding {
  padding: 0;
}

.description {
  margin-left: 24px;
  color: rgba(29, 66, 109, 0.6);
  font-size: 14px;
}

.open{
  background: linear-gradient(66.36deg, rgba(1, 214, 185, 0.45) 32.49%, rgba(33, 143, 238, 0.45) 72.8%);
}

.open:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.actionsWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.hideArrow {
    padding-left: 24px;
}

.hideArrow:before {
  display: none!important;
}
