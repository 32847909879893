.card {
  box-shadow: var(--cardShadow);
  border-radius: 9px;
  background-color: #fff;
  max-width: 100%;
  width: 430px;
  position: relative;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, box-shadow;
}

:global(.darkMode) .card {
  background-color: var(--bgDark);
}

.viewProfileLink span {
  white-space: pre-wrap;
  line-height: 1;
}

.card:hover {
  box-shadow: var(--cardShadowMedium);
  transform: scale(1.07);
}

.cardBody {
  padding: 25px 30px;
}

.cardInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.cardInfoLeft {
  display: flex;
  align-items: center;
}

.cardInfoRight {
  display: flex;
  align-items: center;
  background: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
  padding: 4px 8px;
  border-radius: 100px;
}

.cardInfoRight > *:last-child {
  margin-left: 4px;
}

.headTag {
  border-radius: 100px;
  background-image: linear-gradient(
    270deg,
    rgba(32, 145, 239, 0.1) 0%,
    rgba(26, 157, 225, 0.1) 31.11%,
    rgba(13, 188, 205, 0.1) 73.89%,
    rgba(1, 214, 185, 0.1) 100%
  );
  padding: 5px 10px;
  margin-right: 10px;
  text-align: center;
}

.description {
  margin-bottom: 20px;
  height: 38px;
  overflow-wrap: break-word;
}

@media (--maxWidth600) {
  .description {
    max-width: calc(100vw - 180px);
  }
}

@media (max-width: 500px) {
  .description {
    max-width: calc(100vw - 120px);
  }
}


.controls {
  display: flex;
  justify-content: space-between;
}

.controlButton {
  width: 48%;
}

@media (--maxWidth600) {
  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cardInfo {
    position: absolute;
    top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: calc(100% - 48px);
  }

  .cardInfoWithoutOpen {
    width: initial;
    justify-content: center;
  }

  .cardInfoLeft {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardInfoLeft > *:last-child {
    margin-top: 4px;
  }

  .headTag {
    margin: 0;
  }

  .description {
    height: auto;
    text-align: center;
  }

  .userPreview {
    max-width: 100%;
  }

  .userPreview [class*='profileInfo'] {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .userPreview [class*='profileImage'] {
    margin-bottom: 15px;
    margin-right: 0 !important;
    margin-top: 55px;
  }

  .userPreview [class*='row'] {
    justify-content: center;
  }

  .controls {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .controls [class*='secondary']::after {
    background-image: none;
  }
}
