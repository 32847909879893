.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 55px;
  row-gap: 20px;
  text-align: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  padding: 0 15px;
}

.vimeoVideo {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-bottom: 8px;
}

.vimeoVideo iframe, .vimeoVideo object, .vimeoVideo embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

@media (--maxWidth900) {
  .main {
    grid-template-columns: 1fr;
    margin-bottom: 50px;
  }

  .info {
    padding: 0;
  }
}

.reverse > .image {
  order: 1
}

.reverse > .info {
  order: 2
}

@media (--maxWidth900) {
  .reverse > .image {
    order: 2
  }

  .reverse > .info {
    order: 1
  }
}

.image {
  border-radius: 15px;
  overflow: hidden;
  max-width: 100%;
  display: inherit;
}

@media (--maxWidth1200) {
  .image {
    border-radius: 15px 0 0 15px;
  }

  .reverse .image {
    border-radius: 0 15px 15px 0;
  }
}

@media (--maxWidth900) {
  .image {
    border-radius: 15px!important;
  }
}

.image img {
  max-width: 100%;
  height: auto;
}

.info {
  text-align: left;
}

.title {
  margin-bottom: 24px;
}
