.widget {
    margin-top: 40px;
    white-space: normal;
}

.widget a {
    background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    word-break: break-all;
}

.commonBlock {
    margin-bottom: var(--offsetExtraLarge);
}

.commonBlock > *:first-child {
    margin-bottom: var(--offsetMedium);
}

.descriptionBlock {
    composes: commonBlock;
}

.whatToExpectBlock {
    composes: commonBlock;
}

.responsibilitiesBlock {
    composes: commonBlock;
}

.responsibilitiesBlock ul {
    list-style: none;
    list-style-type: none;
}

.responsibilitiesBlock ul li {
    margin-bottom: var(--offsetMedium);
}

.responsibilitiesBlock ul li:before {
    display: inline-block;
    content: url("/icons/medyoucateSmallLogo.svg");
    width: 14px;
    height: 16px;
    vertical-align: middle;
    margin-right: var(--offsetExtraSmall);
}

.responsibilitiesText ol {
    list-style: inside;
    list-style-type: decimal;
}

.responsibilitiesText * {
    line-height: 1.3;
}

.requirementsBlock {
    composes: commonBlock;
}
