.tableWithAction {
  width: 100%;
}

.tableHeader th:first-child {
  padding-left: var(--offsetSmall);
  padding-right:  var(--offsetExtraSmall);
  min-width: 146px;
}

.tableContent td:not(:first-child) {
  padding: 0 var(--offsetExtraSmall);
  text-align: center;
}

.tableHeader th:last-child{
  min-width: 100px;
  width: 1%;
  white-space: nowrap;
}

.tableHeader th:not(:first-child) {
  padding: 0 var(--offsetExtraSmall);
  text-align: center;
}

.tableHeader th {
  text-align: left;
  vertical-align: middle;
  background: #F4F6F8;
  height: 37px;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #DDE3E9;
}

.tableContent td:first-child {
  padding-left: var(--offsetSmall);
  padding-right:  var(--offsetExtraSmall);
}

@media (hover: hover) {
  .tableContent:hover td {
    background: #F4F9FE;
    transition: .3s ease-in-out;
  }
}

.tableContent td {
  padding: var(--offsetMedium) 0;
  vertical-align: middle;
  border-bottom: 1px solid #DDE3E9;
}

.tableContent td:last-child {
  width: 1%;
  white-space: nowrap;
}

.newslettersDisabled,
.newsletters {
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewsCount {
  z-index: 1;
  cursor: pointer;
  position: relative;
}

.withoutPointer {
  cursor: initial;
}

.newslettersDisabled svg,
.newsletters svg {
  margin-right: var(--offsetExtraSmall);
}

.newslettersDisabled svg path {
  fill: #BBC6D3;
}

.actionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionIcon svg {
  width: 17px;
  cursor: pointer;
}

.positionName {
  cursor: pointer;
}

.actionIcon > span:not(:last-child) {
  margin-right: 30px;
}

.disableIcon {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.withPagination {
  display: grid;
  gap: var(--offsetLarge);
}

.tablesWrapper {
  overflow: auto;
  padding-bottom: 5px;
}
