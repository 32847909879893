.tooltipWrapper {
  display: inline-block;
}

.tooltip {
  --tooltipBackground: #fff !important;
  --tooltipBorder: #ffffff !important;
  background: #FFFFFF !important;
  box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13) !important;
  padding: 12px 20px !important;
  text-align: center !important;
  border: none !important;
  border-radius: 16px !important;
  max-width: 600px;
}

.maxWidth330 {
  max-width: 330px;
  min-width: 200px;
}

.maxWidth600 {
  max-width: 600px;
  min-width: 400px;
}

@media (--maxWidth600) {
  .maxWidth600 {
    max-width: auto;
    min-width: auto;
  }
}
