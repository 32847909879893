@keyframes bgScroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 10000%;
  }
}

@keyframes bgScrollInverse {
  0% {
    background-position: 0 10000%;
  }
  100% {
    background-position: 0 0;
  }
}

.section {
  background: #1D426D;
  min-height: 534px;
  box-shadow: inset  0px 0px 1px #1D426D;
  position: relative;
  overflow: hidden;
  background-image: url(/images/hero/HeroWithEmailbg.jpg);
  background-repeat: no-repeat;
  background-position:  center center;
  background-size: cover;
}

.section:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background: linear-gradient(180deg, rgba(255,0,0,0) 1%, rgba(29, 66, 109, 0.35) 33%, rgba(29, 66, 109, 0.55) 85%);
}

.section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background: linear-gradient(0, rgba(255,0,0,0) 1%, rgba(29, 66, 109, 0.35) 33%, rgba(29, 66, 109, 0.91) 85%);
  z-index: 4;
}

.container {
  width: 100%;
  max-width: 1205px;
  min-width: 320px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 582px 1fr;
}

.coursesWithAnimaion {
  margin-left: 10vw;
  height: 100%;
  position: relative;
}

.mainPosition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 235px 235px 235px;
}

.courses1,
.courses2,
.courses3 {
  width: 100%;
  height: 100%;
}

.courses1 {
  content: ' ';
  animation: bgScroll 2000s linear infinite;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url("/images/hero/1_forAnimation.png");
}

.courses2 {
  content: ' ';
  animation-delay: 250ms;
  animation: bgScrollInverse 2000s linear infinite;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url("/images/hero/2_forAnimation.png");
}

.courses3 {
  content: ' ';
  animation-delay: 650ms;
  animation: bgScroll 2000s linear infinite;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url("/images/hero/2_forAnimation.png");
}

.subtitle {
  margin-bottom: 16px;
}

.content {
  padding: 100px 0;
  min-height: 537px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (--maxWidth900) {
  .coursesWithAnimaion {
    display: none;
  }

  .container {
    grid-template-columns: 100%;
    max-width: 600px;
    text-align: center;
  }
}


@media screen and (--maxWidth600) {
  .title * {
    font-size: 35px!important;
  }
}


.videoPopupContent {
  width: 90vw;
  max-width: 1200px;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
  background: #FFFFFF;
}

.videoPopupContent .loadingVideoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30px;
  width: 30px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.mediaContainer {
  transform: translateZ(0);
  filter: drop-shadow(0px 26px 26px rgba(10, 31, 68, 0.12));
  height: 60vw;
  max-height: 900px;
  background-image: url('/images/landing-hero-free-trial.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.videoPopupContent > * {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
