.main * {
  padding: initial;
  margin: initial;
  list-style-type: inherit;
  font-family: var(--primaryFont);
}

.main ul,
.main ol {
  padding-left: var(--offsetMedium)
}

.main h1 {
  font-size: 32px;
}

.main h2 {
  font-size: 24px;
}

.main h3{
  font-size: 18px;
}

.main h4{
  font-size: 17px;
}

.main h5{
  font-size: 16px;
}

.main h6{
  font-size: 15px;
}

.main p {
  line-height: 1.6;
  font-size: 18px;
  color: var(--primaryFont);
}

.main a {
  background: linear-gradient(270deg, #2091EF 0%, #1A9DE1 31.11%, #0DBCCD 73.89%, #01D6B9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  word-break: break-word;
}

.main h2 {
  font-size: 40px;
  line-height: 1.6;
  color: var(--primaryFont);
}

@media screen and (--maxWidth600) {
  .main h2 {
    font-size: 24px;
  }
}
