.checkboxBlock {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.iconBlock {
  cursor: pointer;
  min-width: 14px;
  min-height: 14px;
}

.label {
  margin-left: 12px;
  font-family: var(--primaryFont);
  color: var(--primaryColorMuted);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}

.labelPrimary {
  font-weight: 600;
  font-size: 16px;
  color: var(--primatyColor);
}

.width362 {
  max-width: 362px;
}

.errorField {
  margin: 4px 0 0 28px;
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 121.5%;
  color: var(--redColor);
}
