.widget {
  max-width: 280px
}

@media (--maxWidth900) {
  .widget {
    max-width: initial;
    width: 100%;
  }
}

.visitBlock {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

@media (--maxWidth900) {
  .visitBlock {
    margin-bottom: 15px;
  }
}

.arrowBlock {
  position: absolute;
  top: -7px;
  right: -15px;
}

.topSeparator {
  margin-bottom: 30px;
}

.bottomSeparator {
  margin: 20px 0 40px;
}
