.main {
    position: relative;
    cursor: pointer;
    z-index: 100;
}

.dropdownWrapper {
    padding-top: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-in;
}

.leftSide {
    left: initial;
    right: 0;
}

.dropdownList {
    width: 100%;
    background: #FFFFFF;
    min-width: 190px;
    box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.3);
    border-radius: 5px;
    padding: var(--offsetMedium) 0 var(--offsetLarge) 0;
    white-space: nowrap;
    position: relative;
}

.main:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFFFFF;
    box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
    visibility: hidden;
    opacity: 0;
    transition: .2s ease-in;
}

.asideCurret .dropdownWrapper:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFFFFF;
    box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13);
    transition: .2s ease-in;
    bottom: 50%;
    right: 100%;
    transform: rotate(-90deg) translate(0, 50%);
}

.asideCurret:after {
    display: none;
}

.asideDropDown{
    left: 110%;
    transform: translate(0, -58%);
}

.dropdownList .dropdownListItem {
    margin-right: 0;
    cursor: pointer;
    padding: var(--offsetSmall) var(--offsetMedium);
    transition: .2s ease-in-out;
    position: relative;
}

.dropdownListItem:hover {
    background: var(--primatyColor);
    color: #FFFFFF;
}

.dropdownList .dropdownListItemActive {
    background: var(--colorGradient);
    color: #FFFFFF;
}

.main:hover .dropdownWrapper {
    visibility: visible;
    opacity: 1;
}

.main:hover:after {
    visibility: visible;
    opacity: 1;
}

.withBorder:not(:hover):after {
    content: ' ';
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #DDE3E9;
}
