.card {
    width: 275px;
    background: #FFFFFF;
    box-shadow: 0 4px 28px rgba(0, 82, 153, 0.13);
    border-radius: 9px;
    padding: var(--offsetMedium);
    margin: 16px 10px;
    cursor: pointer;
}

.withoutOffset {
    margin: 0 ;
}

.firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.workTypesBlock {
    gap: 5px;
    display: flex;
    flex-direction: row;
    margin-top: var(--offsetSmall);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.salaryBlock {
    display: flex;
    align-items: baseline;
}

.minSalary {
    margin-left: 4px;
}

.workTypeBlock {
    background: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
    border-radius: 100px;
    padding: 4px 8px;
    white-space: nowrap;
}

.titleBlock {
    margin-top: var(--offsetSmall);
    text-align: center;
}

.clinicNameBlock {
    margin-top: var(--offsetSmall);
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.entryDate {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.entryDate span{
    text-transform: capitalize;
}

.entryDateIcon {
    margin-right: var(--offsetExtraSmall);
}

.entryDateIcon svg {
    width: 10px !important;
    height: 10px !important;
}

.clinicIcon {
    margin-right: var(--offsetExtraSmall);
}

.locationBlock {
    display: flex;
    align-items: center;
}

.locationBlock > *:last-child {
    margin-left: var(--offsetExtraSmall);
}

.descriptionBlock {
    margin-top: var(--offsetMedium);
    text-align: center;
}

.applyNowButton {
    margin-top: var(--offsetMedium);
}

.daysLeftBlock {
    text-align: center;
    margin-top: var(--offsetSmall);
}


.clinicIcon,
.entryDateIcon,
.locationIcon {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
